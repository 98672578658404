export enum InputType {
    TEXT,
    ONLY_TEXT,
    HIDDEN,
    TEXTAREA,
    EDIT_TEXT,
    CHECK_BOX_TRISTATE,
    EDIT_TEXTAREA,
    EMPTY,
    PASSWORD,
    DROPDOWN,
    RO_DROPDOWN,
    DROPDOWN_SEARCH,
    DROPDOWN_MULTISELECT_SEARCH,
    MULTISELECT,
    CALENDAR,
    EXTERNAL,
    EDIT_NUMERIC,
    TIME,
    SWITCH,
    RO_SWITCH,
    RO_MD_SWITCH,
    SELECT_BUTTON,
    EDIT_TEXT_MASK,
    TWO_FIELDS_CONCAT,
}