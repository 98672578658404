import { features } from './features'; 

features.applicationType = 'agrogest';

// FITOGEST
features.showVentas = false;
features.showVisitas = false;
features.showCargaPAC = false;
features.showTratamientosMenu = false;
features.showCuadernoDeCampo = false;
features.fincaName = 'Campo';
features.fincasName = 'Campos';

// AGROGEST
features.hasClients = false;
features.showSectores = false;
features.showTareasFitosanitarias = false;
features.showTareasFertilizantes = false;
features.showTratamientosManezLozano = true;

// Máñez y Lozano
features.showPerfilUnidades = true;
features.showCampos = true;
features.hasFincas = false;
features.showMeteoBtn = true;
features.showTrabajadores = false;
features.showAplicadoresMenu = false;
features.showMaquinariaMenu = true;
features.showMaquinariaGps = true;
features.hasProductos = false;
features.showProductosSimple = true;
features.showUsuarios = true;
features.showMaquinariaMostrarTodos = true;
features.showProductosMostrarTodos = true;
features.userCanSeeAllRecords = true;
features.showTratamientosControl = true;
features.showVentas = false;
features.forceHiddenVentas = true;
features.showVademecumMenu = false;

export const environment = {
    production: true,
    v: '4',
    serverUrl: 'https://appmyl.com/',
    logoUrl: 'assets/logo_manezylozano.png',
    appName: 'manezylozano',
    features: features
};
