import { Utils } from './../../../../../common/utils';
import { HttpClient } from '@angular/common/http';
import { Component, Input, ViewChild } from '@angular/core';
import { MessageService } from 'primeng/api';
// @ts-expect-error No tiene el tipado requerido port TypeScripn
import google from '@google/maps';
import { TratamientosApiService } from '../../../../../service/api/tratamientos-api.service';

import { GraficasService } from '../../../../../service/api/graficas.service';
import moment from 'moment';
import { firstValueFrom } from 'rxjs';

import type { OnDestroy } from '@angular/core';
import { environment } from 'src/environments/environment';
import { TratamientoManezModel } from 'src/app/models/tratamientos/tratatamiento-manez.model';
import { Dataset, GraficaData, GraficaManez } from '../../trata-manez/grafica-manez.interface';
import { TronicTelemetryInterface } from './tronic-telemetry.interface';
import { BaseResponseModel } from 'src/base-response.model';
// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare let google: any;

@Component({
    selector: 'app-dialog-tratamiento-manez',
    templateUrl: './dialog-tratamiento-manez.component.html',
    styleUrls: ['./dialog-tratamiento-manez.component.scss'],
    providers: [MessageService]
})
export class dialogTratamientoManezLozanoChartsComponent implements OnDestroy {

    @Input() graficacaudal = '';
    @Input() showGraficas = false;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @ViewChild('gmap', {static: false}) gmapElement: any;
    
    public showSpinner = true;
    
    public grafica_flow: GraficaData = {
        labels: [],
        datasets: [{ label: '', data: [] }]
    };
    public grafica_pressure: GraficaData = {
        labels: [],
        datasets: [{ label: '', data: [] }]
    };
    public grafica_vel: GraficaData = {
        labels: [],
        datasets: [{ label: '', data: [] }]
    };
    public grafica_dose: GraficaData = {
        labels: [],
        datasets: [{ label: '', data: [] }]
    };
    public grafica_rpm: GraficaData = {
        labels: [],
        datasets: [{ label: '', data: [] }]
    };
    public tracks: string[] | null = [];
    public tracks_empty: string[] | null = [];
    public tracksRoute: TronicTelemetryInterface[] | null = [];
    
    public showDialog = false;
    public showMap = true;
    public title_modal = '';

    public receta: TratamientoManezModel = {fecha: ''};
    public showReceta  = false;
    public showEnviarCorreo = true;
    public isGeneratingCanvas = false;
    public showSpeed = true;
    public showPressure = true;
    public showFlow = true;
    public showDose = false;
    public updateMap = false;
    public showMapRoute = true;
    public showMapDirections = true;
    public intervaloRecorrido: string | number | NodeJS.Timeout | undefined;

    public aux_data: string | null = null;
    public data: TratamientoManezModel | null = null;
    public verbose: boolean | null = null;

    public deleteWarning = '';
    public parcelasFormatted = '';

    public quantityFlow = 0;

    public fecha_original = '';
    public datos_track = '';


    public data_dose_user: { id_usuario: string; ratio_ha: string; uds_medida: string } = {
        id_usuario: '',
        ratio_ha: '',
        uds_medida: '',
    };

    public maquinaria_aux: TratamientoManezModel = {fecha: ''};
    fecha = '';
    fecha_anterior = '';
    quantityPressure = '';
    quantityVelocidad = '';
    quantityDosis = '';
    quantityRpm = '';

    map: google.maps.Map = null;

    geocoder: google.maps.Geocoder = null;

    routeDraw: BaseResponseModel<TronicTelemetryInterface> = {statusCode:0, statusMessage: '', data: []};

    private infowindow: google.maps.InfoWindow = new google.maps.InfoWindow();


    constructor(public tratamientosApi: TratamientosApiService,
        private graficaService: GraficasService,
        private http: HttpClient
    ) {

    }
  
    ngOnDestroy(): void {
    //alert(' Close Window');
        this.routeDraw = {statusCode:0, statusMessage: '', data: []};
        this.map = [];
    }

    public async loadResumeTronic(data: TratamientoManezModel, verbose = true, hidden_charts: boolean = true) {
        this.initializeMap();

        this.showSpinner = true;
    
        this.routeDraw = {statusCode:0, statusMessage: '', data: []};
        this.map = [];
        this.updateMap = false;
        this.data = data;
        this.verbose = verbose;
        if (hidden_charts === true) {
            this.showGraficas = false;
        }

        this.showMap = false;
        this.data_dose_user.id_usuario = data.id_usuario ?? '';
        this.data_dose_user.ratio_ha = data.ratio_ha?.toString() ?? '';
        this.data_dose_user.uds_medida = data.uds_medida ?? '';

        this.data.aux_fecha = this.data.fecha?.replace(/-/g, '/') ?? '';
        const imei = data.maquinaria?.imei;

        this.title_modal = 'Resumen Sistronic';
        this.aux_data = null;

        if (imei == null || imei.length === 0) {
            alert('Maquinaria sin imei asignado');
            return;
        }

        const fecha = data.fecha;
        this.fecha = data.fecha ?? '';

        this.showMap = true;

        setTimeout(() => {

            if (verbose) {
                this.grafica_flow = {
                    labels: [],
                    datasets: [{ label: '', data: [] }]
                };
                this.grafica_pressure = {
                    labels: [],
                    datasets: [{ label: '', data: [] }]
                };
                this.grafica_vel = {
                    labels: [],
                    datasets: [{ label: '', data: [] }]
                };
        
                this.showDialog = true;
                this.showMap = true;
        
                firstValueFrom(this.graficaService.getManezGraficasCalendar(imei, fecha))
                    .then((grafica: GraficaManez) => {
                        this.showSpinner = false;

                        if (
                            (grafica.flow.datasets as Dataset).data.length === 0 || 
                            (grafica.pressure.datasets as Dataset).data.length === 0 || 
                            (grafica.vel.datasets as Dataset).data.length === 0
                        ) {
                            if (hidden_charts === true) {
                                this.showGraficas = false;
                            }
                            return alert('Registro sin resultados');
                        }


                        setTimeout(async () => {
                            this.map = new google.maps.Map(this.gmapElement.nativeElement, {
                                zoom: 10,
                                //center: new google.maps.LatLng(this.lat, lng),
                                zoomControl: true,
                                zoomControlOptions: {
                                    position: google.maps.ControlPosition.RIGHT_TOP
                                },
                                mapTypeId: google.maps.MapTypeId.HYBRID,
                                mapTypeControlOptions: {
                                    mapTypeIds: ['roadmap', 'hybrid'],
                                    position: google.maps.ControlPosition.TOP_RIGHT,
                                    labels: true
                                },
                                disableDefaultUI: false,
                                fullscreenControl: false,
                                streetViewControl: false,
                                tilt: 0

                            });
                            this.quantityFlow = 0;
                            this.grafica_flow = await this.fillChartDecimals(grafica.flow, 0);

                            const resultadoFlow = (this.grafica_flow.datasets as Dataset).data.reduce(this.getSum, 0);
                            if (resultadoFlow && this.grafica_flow.datasets as Dataset) {
                                this.quantityFlow = 
                                +Utils.decimalFormat(resultadoFlow / (this.grafica_flow.datasets as Dataset).data.length, 2);
                            }

                            this.grafica_pressure = await this.fillChartDecimals(grafica.pressure, 0);
                            const resultadoPressure = (this.grafica_pressure.datasets as Dataset).data.reduce(this.getSum, 0);
                            if (resultadoPressure && this.grafica_pressure.datasets as Dataset) {
                                this.quantityPressure = 
                                    Utils.decimalFormat(resultadoPressure / (this.grafica_pressure.datasets as Dataset).data.length, 2);
                            }

                            this.grafica_vel = await this.fillChartDecimals(grafica.vel, 1);
                            console.log(this.grafica_vel);
                            const resultadoVelocidad = (this.grafica_vel.datasets as Dataset).data.reduce(this.getSum, 0);
                            if (resultadoVelocidad && this.grafica_vel.datasets as Dataset) {
                                this.quantityVelocidad = 
                                    Utils.decimalFormat(resultadoVelocidad / (this.grafica_vel.datasets as Dataset).data.length, 2);
                            }

                            this.grafica_dose = await this.fillChartDecimals(grafica.dose, 0);
                            const resultadoDosis = (this.grafica_dose.datasets as Dataset).data.reduce(this.getSum, 0);
                            if (resultadoDosis && this.grafica_dose.datasets as Dataset) {
                                this.quantityDosis = 
                                Utils.decimalFormat(resultadoDosis / (this.grafica_dose.datasets as Dataset).data.length, 2);
                            }

                            this.grafica_rpm = await this.fillChartDecimals(grafica.rpm, 0);
                            const resultadoRpm = (this.grafica_rpm.datasets as Dataset).data.reduce(this.getSum, 0);
                            if (resultadoRpm && this.grafica_rpm.datasets as Dataset) {
                                this.quantityRpm = 
                                Utils.decimalFormat(resultadoRpm / (this.grafica_rpm.datasets as Dataset).data.length, 2);
                            }

                            this.getTracksMyL(imei, fecha);
                            this.showDose = true;


                        });
                        return;
                    }).catch (e => {
                        console.log('catch en graficaService: ' + e);
                    }
                    );
            }
        }, 500);

    }

    public mostrarGrafica(){
        this.showGraficas = true;
    }

    public async loadResumeTronicAddDay(data: TratamientoManezModel, verbose: boolean = true, hidden_charts: boolean = true) {
        this.initializeMap();

        this.updateMap = false;
        this.data = data;
        this.verbose = verbose;
        if (hidden_charts === true) {
            this.showGraficas = false;
        }

        this.showMap = false;
        this.data_dose_user.id_usuario = data.id_usuario ?? '';
        this.data_dose_user.ratio_ha = data.ratio_ha?.toString() ?? '';
        this.data_dose_user.uds_medida = data.uds_medida ?? '';
        //console.log('data', data);

        this.data.aux_fecha = this.data?.fecha?.replace(/-/g, '/') ?? '';
        const imei = data.maquinaria?.imei;

        this.title_modal = 'Resumen Sistronic';
        this.aux_data = null;

        if (imei == null || imei.length === 0) {
            alert('Maquinaria sin imei asignado');
            return;
        }

        const fecha = data.fecha;
        this.fecha = data.fecha ?? '';

        this.showMap = true;

        setTimeout(() => {

            if (verbose) {
                //super.show(null, aux_data);
                //this.grafica_flow = null;
                //this.grafica_pressure = null;
                //this.grafica_vel = null;

                firstValueFrom(this.graficaService.getManezGraficasCalendar(imei, fecha))
                    .then((grafica: GraficaManez) => {

                        if (
                            (grafica.flow.datasets as Dataset).data.length === 0 || 
                            (grafica.pressure.datasets as Dataset).data.length === 0 || 
                            (grafica.vel.datasets as Dataset).data.length === 0
                        ) {
                            if (hidden_charts === true) {
                                this.showGraficas = false;
                            }
                            return alert('Registro sin resultados');
                        }


                        this.showMap = true;
                        setTimeout(async () => {
                            this.map = new google.maps.Map(this.gmapElement.nativeElement, {
                                zoom: 10,
                                //center: new google.maps.LatLng(this.lat, lng),
                                zoomControl: true,
                                zoomControlOptions: {
                                    position: google.maps.ControlPosition.RIGHT_TOP
                                },
                                mapTypeId: google.maps.MapTypeId.HYBRID,
                                mapTypeControlOptions: {
                                    mapTypeIds: ['roadmap', 'hybrid'],
                                    position: google.maps.ControlPosition.TOP_RIGHT,
                                    labels: true
                                },
                                disableDefaultUI: false,
                                fullscreenControl: false,
                                streetViewControl: false,
                                tilt: 0 // para que no se muestre el mapa con 45º

                            });
                            // console.log('grafica.flow', grafica.flow);

                            this.quantityFlow = 0;
                            this.showSpeed = false;
                            const flow: GraficaData = (await this.fillChartDecimals(grafica.flow, 0));

                            (this.grafica_flow?.datasets as Dataset).data.forEach((element: number) => {
                                (flow.datasets as Dataset).data.push(element);
                            });

                            this.grafica_flow?.labels.forEach((element: string) => {
                                flow.labels.push(element);
                            });

                            this.grafica_flow = flow;

                            const resultadoFlow = (this.grafica_flow.datasets as Dataset).data.reduce(this.getSum, 0);

                            if (resultadoFlow && this.grafica_flow.datasets as Dataset) {
                                this.quantityFlow = 
                                +Utils.decimalFormat(resultadoFlow / (this.grafica_flow.datasets as Dataset).data.length, 2);
                            }

                            const pressure: GraficaData = (await this.fillChartDecimals(grafica.pressure, 0));

                            (this.grafica_pressure?.datasets as Dataset).data.forEach((element: number) => {
                                (pressure.datasets as Dataset).data.push(element);
                            });

                            this.grafica_pressure?.labels.forEach((element: string) => {
                                pressure.labels.push(element);
                            });

                            this.grafica_pressure = pressure;

                            const resultadoPressure = (this.grafica_pressure.datasets as Dataset).data.reduce(this.getSum, 0);
                            if (resultadoPressure && this.grafica_pressure.datasets as Dataset) {
                                this.quantityPressure = 
                                    Utils.decimalFormat(resultadoPressure / (this.grafica_pressure.datasets as Dataset).data.length, 2);
                            }

                            const velocidad: GraficaData = (await this.fillChartDecimals(grafica.vel, 0));

                            (this.grafica_vel?.datasets as Dataset).data.forEach((element: number) => {
                                (velocidad.datasets as Dataset).data.push(element);
                            });

                            this.grafica_vel?.labels.forEach((element: string) => {
                                velocidad.labels.push(element);
                            });

                            this.grafica_vel = velocidad;

                            const resultadoVelocidad = (this.grafica_vel.datasets as Dataset).data.reduce(this.getSum, 0);
                            if (resultadoVelocidad && this.grafica_vel.datasets as Dataset) {
                                this.quantityVelocidad = 
                                    Utils.decimalFormat(resultadoVelocidad / (this.grafica_vel.datasets as Dataset).data.length, 2);
                            }

                            const dosis: GraficaData = (await this.fillChartDecimals(grafica.dose, 0));

                            (this.grafica_dose?.datasets as Dataset).data.forEach((element: number) => {
                                (dosis.datasets as Dataset).data.push(element);
                            });

                            this.grafica_dose?.labels.forEach((element: string) => {
                                dosis.labels.push(element);
                            });

                            this.grafica_dose = dosis;

                            const resultadoDosis = (this.grafica_dose.datasets as Dataset).data.reduce(this.getSum, 0);

                            if (resultadoDosis && this.grafica_dose.datasets as Dataset) {
                                this.quantityDosis = 
                                    Utils.decimalFormat(resultadoDosis / (this.grafica_dose.datasets as Dataset).data.length, 2);
                            }

                            const rpm: GraficaData = (await this.fillChartDecimals(grafica.rpm, 0));

                            (this.grafica_rpm?.datasets as Dataset).data.forEach((element: number) => {
                                (rpm.datasets as Dataset).data.push(element);
                            });

                            this.grafica_rpm?.labels.forEach((element: string) => {
                                rpm.labels.push(element);
                            });

                            this.grafica_rpm = rpm;

                            const resultadoRpm = (this.grafica_rpm.datasets as Dataset).data.reduce(this.getSum, 0);
                            if (resultadoRpm && (this.grafica_rpm.datasets as Dataset)) {
                                this.quantityRpm = 
                                Utils.decimalFormat(resultadoRpm / (this.grafica_rpm.datasets as Dataset).data.length, 2);
                            }


                            setTimeout(() => {
                                this.showSpeed = true;
                            }, 500);  //2000


                            this.getTracksMyL(imei, fecha);
                            this.showDose = true;


                        });
                        return;
                    }).catch (e => {
                        console.log('catch en getPosition: ' + e);
                    }
                    );
            }
        }, 500);

    }

    getSum(total: number, num: number) {
        return parseFloat(total.toString()) + Math.round(parseFloat(num.toString()));
    }

    calculateLiteHectarea(liters: number, ratio_ha: number) {

        if (ratio_ha == null || liters == null) {
            return 0;
        }

        if (ratio_ha === 0 || liters === 0) {
            return 0;
        }

        else {
            const resultado = ((ratio_ha / liters) * 10000).toFixed(2);
            return resultado;
        }
    }

    deleteInterval() {
        clearInterval(this.intervaloRecorrido);
    }

    splitProductosUnidades(value: string) {
        const productoUnidades = value.split(' ');
        return Utils.decimalFormat(productoUnidades[0]?.toString() ?? '', 2) + '' + productoUnidades[1] ? productoUnidades[1] : '';
    }

    fillChartDecimals(chart: GraficaData, decimals: number | undefined) {

        return new Promise<GraficaData>(resolve => {
            const dataset = chart.datasets as Dataset;
            if (dataset) {
                for (let index = 0; index < dataset.data.length; index++) {
                    const data = dataset.data[index];
                    if (data == null || data === 0) {
                        dataset.data[index] = 0;
                    } else {
                        dataset.data[index] = +parseFloat(data.toString()).toFixed(decimals);
                    }
    
                    if ((index + 1) >= dataset.data.length) {
                        resolve(chart);
                    }
                }
    
            }
        });

    }

    routeReDraw() {

        const data = this.data;
        if (this.updateMap) {
            this.deleteInterval();
            this.updateMap = false;
            return;
        }

        this.updateMap = true;
        if (data && this.data) {
            this.data_dose_user.id_usuario = data.id_usuario ?? '';
            this.data_dose_user.ratio_ha = data.ratio_ha?.toString() ?? '';
            this.data_dose_user.uds_medida = data.uds_medida ?? '';
            this.data.aux_fecha = this.data?.fecha?.replace(/-/g, '/') ?? '';
            const imei = data.maquinaria?.imei;
            this.title_modal = 'Resumen Sistronic';
            this.aux_data = null;
            this.showMap = true;
            this.intervaloRecorrido = setInterval(() => {
                this.grafica_flow = {
                    labels: [],
                    datasets: [{ label: '', data: [] }]
                };
                this.grafica_pressure = {
                    labels: [],
                    datasets: [{ label: '', data: [] }]
                };
                this.grafica_vel = {
                    labels: [],
                    datasets: [{ label: '', data: [] }]
                };
    
                firstValueFrom(this.graficaService.getManezGraficasCalendar(imei ?? '', this.fecha))
                    .then((grafica: GraficaManez) => {
    
                        if (
                            (grafica.flow.datasets as Dataset).data.length === 0 || 
                            (grafica.pressure.datasets as Dataset).data.length === 0 || 
                            (grafica.vel.datasets as Dataset).data.length === 0
                        ) {
                            this.showGraficas = false;
                            return alert('Registro sin resultados');
                        }
    
                        this.showGraficas = true;
                        this.showMap = true;
    
    
                        setTimeout(async () => {
                            this.grafica_flow = await this.fillChartDecimals(grafica.flow, 0);
                            this.grafica_pressure = await this.fillChartDecimals(grafica.pressure, 0);
                            this.grafica_vel = await this.fillChartDecimals(grafica.vel, 1);
                            console.log(this.grafica_vel);
                            this.grafica_dose = await this.fillChartDecimals(grafica.dose, 0);
                            this.grafica_rpm = await this.fillChartDecimals(grafica.rpm, 0);
                            this.getTracksMyL(imei ?? '', this.fecha);
                            this.showDose = true;
                        }, 500);
                        return;
                    }).catch (e => {
                        console.log('catch en getPosition: ' + e);
                    }
                    );
            }, 5000);
        }
    }

    initializeMap() {
        this.map = [];

        window.scrollTo(0, 0);
        window.scrollBy(0, 0);
        window.scroll(0, 0);
    }

    opendialogChart(maq: ControlMaquinarias) {

        const data: TratamientoManezModel = {
            id: maq.id,
            fecha: maq.date,
            fecha_formatted: moment(maq.fecha).format('DD-MM-YYYY'),
            id_maquinaria: maq.id,
            id_usuario: maq.id_usuario,
            ratio_ha: +maq.ratio_ha,
            uds_medida: maq.uds_medida,
            maquinaria_nombre: maq.observaciones,
            campo_nombre: maq.observaciones,
            maquinaria: {
                id: maq.id,
                imei: maq.imei
            }
        };
        this.fecha_original = data.fecha ?? '';

        this.maquinaria_aux = data;

        this.loadResumeTronic(data, true);
    }

    hiddenShowSpeed() {
        this.showSpeed = !this.showSpeed;
    }
    
    public getYestardayTratamientos() {
        this.maquinaria_aux.fecha = this.transformDate(this.maquinaria_aux.fecha ?? '', -1);
        this.maquinaria_aux.fecha_formatted = this.maquinaria_aux.fecha;
        this.fecha_anterior = this.maquinaria_aux.fecha;
        this.loadResumeTronicAddDay(this.maquinaria_aux, true, false);
    }

    public getTomorrowTratamientos() {
        this.maquinaria_aux.fecha = this.transformDate(this.maquinaria_aux.fecha ?? '', 1);
        this.maquinaria_aux.fecha_formatted = this.maquinaria_aux.fecha;
        this.loadResumeTronic(this.maquinaria_aux, true, false);
    }

    public transformDate(fecha_anterior: string, dia: number) {
        const fecha = fecha_anterior.split('-');
        const nuevafecha = new Date(fecha[2] ? +fecha[2] : 0, fecha[1] ? +fecha[1] : 0, fecha[0] ? +fecha[0] : 0);
        nuevafecha.setDate(nuevafecha.getDate() + dia);
        const day = nuevafecha.getDate().toString().length === 1 ? '0' + nuevafecha.getDate().toString() : nuevafecha.getDate().toString();
        const month = 
          nuevafecha.getMonth().toString().length === 1 ? 
              '0' + nuevafecha.getMonth().toString() : 
              nuevafecha.getMonth().toString();
        const year = nuevafecha.getFullYear();

        return day + '-' + month + '-' + year;
    }

    public scrollToTop() {
        const elements = document.getElementsByClassName('ui-dialog-content ui-widget-content');
        if (elements && elements[0]) {
            setTimeout(() => {
                if (elements[0]) {
                    elements[0].scrollTop = 0;
                }
            }, 100);
        }
    }

    private getTracksMyL(imei: string, fecha?: string) {

        const url = environment.serverUrl + 'ws/index.php?p1=tratamientos&p2=get_coord_dismuntel_calendar&imei=' + imei + '&fecha=' + fecha;

        this.http.get<BaseResponseModel<TronicTelemetryInterface>>(url).subscribe((data: BaseResponseModel<TronicTelemetryInterface>) => {
            if (Object.keys(this.routeDraw).length === 0) {
                this.routeDraw = data;
            } else {
                this.routeDraw.data = [
                    ...(this.routeDraw.data as TronicTelemetryInterface[]), 
                    ...(data.data as TronicTelemetryInterface[])
                ];

                this.routeDraw = data;
            }

            this.drawTracks(data);
        });
    }
  
    private drawTracks(data: BaseResponseModel<TronicTelemetryInterface>): void {
    
        if (!this.infowindow) {
            this.infowindow = new google.maps.InfoWindow({ map: this.map });
        }
  
        let lastLatLng: string | null = null;
  
        this.tracksRoute = (data.data as TronicTelemetryInterface[]);
        this.tracksRoute.forEach((track: TronicTelemetryInterface) => {
            const currentLatLng = new google.maps.LatLng(track.lat, track.lon);
            if (!lastLatLng) {lastLatLng = currentLatLng; }
  
            // eslint-disable-next-line no-bitwise
            const res = track.io & 15;
            const polyline = new google.maps.Polyline({
                path: [lastLatLng, currentLatLng],
                strokeColor: res === 0 ? 'yellow' : '#ff3333',
                geodesic: true,
                strokeOpacity: res === 0 ? 0.8 : 1,
                strokeWeight: 5,
            });
  
            polyline.setMap(this.map);
  
            // Reutilización de Event Listeners y InfoWindow
            google.maps.event.addListener(polyline, 'mouseover', () => {
        
                const datosTrackHtml = `
        <div id="hook">
            <p><strong>Hora</strong> ${track.time}<br>
            <strong>Caudal: </strong>${track.flow}<br>
            <strong>Presión: </strong>${track.pressure}<br>
            <strong>Velocidad: </strong>${track.vel}<br>
            <strong>Dosis: </strong>${track.dose}<br>
            <strong>Rpm:</strong> ${track.rpm}</strong>
        </div>`;
                this.infowindow.setContent(datosTrackHtml);
                this.infowindow.setPosition(currentLatLng);
                this.infowindow.open(this.map);
            });
  
            // Asignación del evento mouseout a la polilínea
            this.addMouseOutListener(polyline);
  
            lastLatLng = currentLatLng; // Actualizar la última posición para el siguiente segmento
        });
  
    
        if (lastLatLng) {
            this.map.setCenter(lastLatLng);
            this.map.setZoom(17);
        }
    }

    // Método separado para manejar el evento mouseout
    private addMouseOutListener(polyline: unknown): void {
        google.maps.event.addListener(polyline, 'mouseout', () => {
            this.infowindow.close();
        });
    }
}


interface ControlMaquinarias {
    fecha: string;
    date: string;
    id: string;
    id_cliente: string;
    id_maquinaria: string;
    id_usuario: string;
    imei: string;
    observaciones: string;
    ratio_ha: string;
    uds_medida: string;
    maquinaria?: { imei?: string };
    id_telemetry?: string;

}