<div>
    <button 
        class="btn mx-2" 
        [ngClass]="{
            'btn-danger': error || (mode === REQUEST_BUTTON_TYPES.DELETION && !request.isLoading && request.hasError === undefined),
            'btn-warning': warning || request.hasError || request.hasErrorCliExists,
            'btn-primary': !error && !warning && mode !== REQUEST_BUTTON_TYPES.DELETION && !request.isLoading && request.hasError === undefined,
            'btn-success': !error && !warning && request.hasError === false,
            'btn-secondary': request.isLoading
        }" 
        [disabled]="request.isLoading || (request.hasError === false && lockOnSuccess)"
    >
        <i 
            class="fa" 
            [ngClass]="{
                'fa-exclamation-triangle': error || warning || request.hasError || request.hasErrorCliExists,
                'fa-save': mode === REQUEST_BUTTON_TYPES.DUPLICATION || mode === REQUEST_BUTTON_TYPES.EDITION || (!mode && !error && !warning),
                'fa-pencil': mode === REQUEST_BUTTON_TYPES.EDITION,
                'fa-trash': mode === REQUEST_BUTTON_TYPES.DELETION,
                'fa-check': !error && !warning && request.hasError === false,
                'fa-spinner fa-spin': request.isLoading
            }" 
            aria-hidden="true"
        ></i>
        <span [innerHTML]="getButtonLabel()"></span>

        @if(
            !lockOnSuccess && 
            request.hasError === false && 
            (mode === REQUEST_BUTTON_TYPES.DUPLICATION || mode === undefined)
        ){
            <span>{{ mode === REQUEST_BUTTON_TYPES.DUPLICATION ? 'Duplicar otro' : 'Crear otro' }}</span>
        }
    </button>
</div>