import { HttpClient, HttpHeaders } from '@angular/common/http';
import {Component, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {DashboardService} from '../../../../../service/events/dashboard.service';
import {InputType} from '../../../../../common/components/form-builder/form-builder.component';
import {BaseForm} from '../../../../base-form';
import {TrabajadoresApiService} from '../../../../../service/api/trabajadores-api.service';
import {AppFormRequest} from '../../../../app-common/form-request/app-form-request';
import {build, list, stack} from '../../../../../common/classes/request-builder';
import moment from 'moment';
import {StorageManager} from '../../../../../common/storage-manager.class';
import { RequestButtonComponent } from 'src/app/common/components/request-button/request-button.component';
import { environment } from 'src/environments/environment';
import SignaturePad from 'signature_pad';
import { FormRequestTypes } from 'src/app/common/classes/form-request';
import { UploadApiService } from 'src/app/service/api/upload-api.service';
import { ClientesApiService } from 'src/app/service/api/clientes-api.service';
import { RopoApiService } from '../../../../../service/api/ropo-api.service';
import { VentasApiService } from 'src/app/service/api/ventas-api.service';
import { catchError, firstValueFrom, map, of } from 'rxjs';

import type { OnInit } from '@angular/core';
import type { ItemInterface, TypeAheadInterface } from '../../../../../common/components/form-builder/form-builder.component';
import { MantenimientoModel } from 'src/app/models/trabajadores/mantenimiento.model';
import { ClienteModel } from 'src/app/models/cliente.model';
import { Compradores } from 'src/app/models/ventas/compradores.model';
import { MaquinariaModel } from 'src/app/models/trabajadores/maquinaria.model';
import { FileUploadAnswer } from 'src/app/common/utils';

const aplicadorName = environment.features.aplicadorName;

@Component({
    selector: 'app-mantenimiento-form',
    templateUrl: './mantenimiento-form.component.html',
    styleUrls: ['./mantenimiento-form.component.scss']
})
export class MantenimientoFormComponent extends BaseForm<MantenimientoModel> implements OnInit {
    @ViewChild(RequestButtonComponent, { read: RequestButtonComponent, static: true })
        requestButton = new RequestButtonComponent<MantenimientoModel>();
    @ViewChild('signaturePadElement', { static: true }) signaturePadElement!: SignaturePad;
    

    public appName = environment.appName;
    public hasClients = environment.features.hasClients;
    public showTrabajadoresCargos = environment.features.showTrabajadoresCargos;
    public showAplicadoresActivo = environment.features.showAplicadoresActivo;
    public showTrabajadoresFirma = environment.features.showTrabajadoresFirma;
    public filterAplicadoresByClient = environment.features.filterAplicadoresByClient;
    public showTrabajadoresUserYPass = environment.features.showTrabajadoresUserYPass;
    public trabajadoresAsUsuarios = environment.features.trabajadoresAsUsuarios;
    public showTrabajadoresClienteAsignado = environment.features.showTrabajadoresClienteAsignado;
    public aplicadorName = environment.features.aplicadorName;
    public clienteName = environment.features.clienteName;
    public showTrabajadoresCuadrillas = environment.features.showTrabajadoresCuadrillas;
    public showTrabajadoresNumSS = environment.features.showTrabajadoresNumSS;
    public showRopoRomaRegepa = environment.features.showRopoRomaRegepa;
    public showFotoTrajador = environment.features.showFotoTrajador;
    public titleActivo = environment.features.titleActivoMaqTra;    

    public override model = {
        activo: '1'
    } as MantenimientoModel;
    public formRequest = new AppFormRequest<MantenimientoModel>();
    public passErrors = '';
    public showPassErrors = false;
    public isShowingPassword = false;

    public distributionOrder = [
        4, 4, 4
    ];

    public hasSignature = false;
    public modelSignature: string | null = '';
    public urlSignature = this.pathToFirmas + '_trabajadores/' + (this.getRegisterId() || 0) + '.png';

    public tipoCarnet: TypeAheadInterface<string> = {
        selected: null,
        filtered: [
            {label: '...', value: null},
            {label: 'BÁSICO', value: 'BÁSICO'},
            {label: 'CUALIFICADO', value: 'CUALIFICADO'},
            {label: 'FUMIGADOR', value: 'FUMIGADOR'},
            {label: 'PILOTO', value: 'PILOTO'},
            {label: 'ASESOR', value: 'ASESOR'}
        ],
        values: []
    };

    public tipoCarnetPyf: TypeAheadInterface<string> = {
        selected: null,
        filtered: [
            {label: '...', value: null},
            {label: 'BÁSICO', value: 'BÁSICO'},
            {label: 'CUALIFICADO', value: 'CUALIFICADO'},
            {label: 'FUMIGADOR', value: 'FUMIGADOR'},
            {label: 'PILOTO', value: 'PILOTO'}
           
        ],
        values: []
    };

    public maquinaria: TypeAheadInterface<MaquinariaModel> = list(['...', null]);
    public cargos: TypeAheadInterface<string> = list(['...', null]);
    public propiedad: TypeAheadInterface<string> = list(['...', null], ['Propios', 'Propios'], ['Cliente', 'Cliente']);
    public cuadrillas: TypeAheadInterface<string> = list(['...', null]);
    public comercios: TypeAheadInterface<Compradores> = list(['...', null]);
    public clientes: TypeAheadInterface<ClienteModel> = list(['...', null]);

    public formFields: ItemInterface<object>[] = [
        {
            label: 'Nombre', 
            field: 'nombre', 
            inputType: {type: InputType.EDIT_TEXT}, 
            required: true
        },
        {
            label: 'Apellidos', 
            field: 'apellidos', 
            inputType: {type: InputType.EDIT_TEXT}
        },
        {
            label: 'NIF', 
            field: 'nif', 
            inputType: {type: InputType.EDIT_TEXT}
        },
        {
            label: 'Password', 
            field: 'password_fichaje', 
            inputType: {type: InputType.EDIT_TEXT}, 
            visible: this.appName === 'covidai' 
        },
        {
            label: 'Cuadrilla',
            field: 'cuadrilla',
            inputType: {type: InputType.DROPDOWN},
            visible: this.showTrabajadoresCuadrillas,
            valuePrimaryKey: 'id',
            values: this.cuadrillas
        },
        {
            label: 'Nº\xa0Afiliación', 
            field: 'numss', 
            inputType: {type: InputType.EDIT_TEXT}, 
            visible: this.showTrabajadoresNumSS
        },
        {
            label: 'Máquina', 
            field: 'id_maquina', 
            inputType: this.appName === 'pyf' ? {type: InputType.DROPDOWN_SEARCH} : {type: InputType.DROPDOWN},
            values: this.maquinaria,
            valuePrimaryKey: 'id',
            filter: (it: { value: MaquinariaModel }) => {
                if ( this.appName === 'abonosfolques' ) {
                    return true;
                }
                if (it.value.activo !== '1') {
                    return false;
                }
                if (!!StorageManager.getClient() && this.hasClients && this.filterAplicadoresByClient) {
                    return it.value.id_cliente === (StorageManager.getClient() || {}).id;
                } else {
                    return true;
                }
            }
        },
        {
            label: 'Tipo Carnet', 
            field: 'tipo_carnet', 
            inputType: {type: InputType.DROPDOWN}, 
            values: this.appName === 'pyf' ? this.tipoCarnetPyf : this.tipoCarnet
        },
        {
            label: 'ROPO', 
            field: 'ropo', 
            inputType: {type: InputType.EDIT_TEXT}
        },
        {
            label: 'Fecha Expedición', 
            field: 'fecha_exp', 
            inputType: {type: InputType.CALENDAR}
        },
        {
            label: 'Fecha Caducidad', 
            field: 'fecha_cad', 
            inputType: {type: InputType.CALENDAR}
        },
        {
            label: 'Cargo',
            field: 'id_cargo',
            inputType: {type: InputType.DROPDOWN},
            values: this.cargos,
            valuePrimaryKey: 'id',
            visible: this.showTrabajadoresCargos
        },
        {
            label: 'Email', 
            field: 'email', 
            inputType: { type: InputType.EDIT_TEXT}, 
            visible: this.appName === 'fotoagricultura' 
        },
        {
            label: 'Proveedor',
            field: 'id_comercio',
            inputType: {type: InputType.DROPDOWN},
            visible: this.appName === 'sirga',
            valuePrimaryKey: 'id',
            values: this.comercios,
            filter: (it: { value: { tipo: string; }; }) => {
                if (it.value.tipo === 'Proveedor') {
                    return true;
                }
                return false;
            }
        },
        {
            label: 'Observaciones', 
            field: 'observaciones', 
            inputType: {type: InputType.EDIT_TEXTAREA}
        },
        {
            label: 'Propiedad', 
            field: 'propiedad', 
            inputType: {type: InputType.DROPDOWN}, 
            values: this.propiedad, 
            visible: this.appName === 'agropecuariaramirogruas'
        },
        {
            label: 'Usuario', 
            field: 'username', 
            inputType: {type: InputType.EDIT_TEXT},
            visible: this.showTrabajadoresUserYPass
        },
        {
            label: 'Contraseña', 
            field: 'password', 
            inputType: {type: InputType.PASSWORD},
            visible: this.showTrabajadoresUserYPass
        },
        {
            label: 'Repetir contraseña', 
            field: 'password_repeat', 
            inputType: {type: InputType.PASSWORD},
            visible: this.showTrabajadoresUserYPass
        },
        {
            label: this.clienteName + ' asignado',
            field: 'id_cliente_asignado',
            inputType: {type: InputType.DROPDOWN_SEARCH},
            values: this.clientes,
            valuePrimaryKey: 'id',
            visible: this.showTrabajadoresClienteAsignado,
            required: true
        },
        {
            label: 'Almacén', 
            field: 'es_almacen', 
            inputType: {type: InputType.SWITCH}, 
            visible: this.appName === 'naturfrut'
        },
        {
            label: this.titleActivo, 
            field: 'activo', 
            inputType: {type: InputType.SWITCH}, 
            visible: this.showAplicadoresActivo
        },
        {
            label: 'Acceso a Plataforma', 
            field: 'acceso_plataforma', 
            inputType: {type: InputType.SWITCH}, 
            visible: this.appName === 'innovia'
        },
        {
            hasLabel: false,
            field: 'adjunto',
            inputType: {type: InputType.EMPTY},
            visible: this.showFotoTrajador
        }
    ];

    public fieldsToSend = (['id'])
        .concat(
            this.formFields
                .map(it => it.field ?? '')
                .filter(it => !['password', 'password_repeat'].includes(it ?? ''))
        );
    
    public adjunto: string | ArrayBuffer | null | undefined = '';
    
    constructor(
        public override dashboard: DashboardService,
        public override router: Router,
        public override route: ActivatedRoute,
        public trabajadoresApi: TrabajadoresApiService,
        public ventasApi: VentasApiService,
        public clientesApi: ClientesApiService,
        public _ropoService: RopoApiService,
        public uploadApi: UploadApiService,
        private http: HttpClient
         
    ) {
        super(
            route,
            router,
            dashboard,
            trabajadoresApi.trabajadoresPUT,
            trabajadoresApi.trabajadoresPOST,
            'mantenimiento',
            'Actualizar ' + aplicadorName,
            'Crear ' + aplicadorName,
            'Duplicar ' + aplicadorName
        );
    }

    ngOnInit() {
        if ((this.getType() === FormRequestTypes.EDIT) && this.showTrabajadoresFirma) {
            this.fetchSignature();
        }

        if (this.hasClients) {
            this.fieldsToSend.push('id_cliente');
        }

        if (this.appName === 'covidai') {
            this.fieldsToSend.push('password_fichaje');
        }
        this.formRequest
            .setType(this.getType())
            .setRegisterId(this.getRegisterId())
            .setGetRequest(this.trabajadoresApi.trabajadoresGET)
            .setPostRequest(this.trabajadoresApi.trabajadoresPOST)
            .setPutRequest(this.trabajadoresApi.trabajadoresPUT)
            .setModel(this.model)
            .setFormFields(this.formFields)
            .setFieldsToSend(this.fieldsToSend);


        this.formRequest.beforeLoad(resolve => {
            this.initFormData()
                .then(() => {
                    resolve(true);
                    return;
                }).catch (e => {
                    console.log('catch en getPosition: ' + e);
                }
                );
        });

        this.formRequest.afterLoad(resolve => {
            this.model.activo = this.model.activo === '1';
            if (this.appName === 'naturfrut') {
                this.model.es_almacen = this.model.es_almacen === '1';
            }
            this.model.password_repeat = this.model.password ?? '';

            resolve(true);
        });

        this.formRequest.beforeSend(resolve => {
            // Modificar la estructura de la fecha si tiene fecha seleccionada
            if ((typeof this.model.fecha_exp !== 'string') && ![true, 1, '1'].includes(this.model.es_almacen ?? '')) {
                
                if ( ( this.model.fecha_exp !=  null ) ) {
                    this.model.fecha_exp = moment(this.model.fecha_exp ).format('DD/MM/YYYY');
                }
                // La forma antigua para rellerafecha cuando este vacia
                // this.model.fecha_exp = moment(this.model.fecha_exp || new Date()).format('DD/MM/YYYY');
            }
            if ((typeof this.model.fecha_cad !== 'string') && ![true, 1, '1'].includes(this.model.es_almacen ?? '')) {
                if ( ( this.model.fecha_cad !=  null ) ) {
                    this.model.fecha_cad = moment(this.model.fecha_cad ).format('DD/MM/YYYY');
                }
                // La forma antigua para rellerafecha cuando este vacia
                // this.model.fecha_cad = moment(this.model.fecha_cad || new Date()).format('DD/MM/YYYY');
            }

            if (this.showTrabajadoresUserYPass) {
                if (!this.validatePassword()) {
                    this.showPassErrors = true;
                } else {
                    resolve(true);
                }
            } else {
                resolve(true);
            }

        });

        this.formRequest.afterSend(resolve => {
            if (this.modelSignature) {
                this.uploadApi.firmasPOST.toPromise({
                    id: this.model.id ?? '',
                    dir: 'firmas_trabajadores',
                    firma: this.modelSignature
                });
            }
            resolve(true);
        });
    
        this.formRequest.load();

        this.softInit(this.getType());
    }

    public submit() {
        delete this.model.password_repeat;
        if (StorageManager.getUser().tipo === 'comunero') {
            this.model.id_cliente = StorageManager.getUser().id_cliente ?? '';
        }
        
        if (!this.formRequest.checkIfValid()) {
            this.requestButton.error = 'Hay campos obligatorios';
        }

        if (this.appName !== 'fotoagricultura') {
            delete this.model.email;
        }

        if (this.appName !== 'covidai' ) {
            delete this.model.password_fichaje;      
        }

        if (this.appName !== 'agropecuariaramirogruas' ) {
            delete this.model.propiedad;      
        }
        this.formRequest.send();
    }

    public formChanges(tag: string) {
        this.requestButton.error = '';
      
        // Calculo automático de ROPO al introducir NIN si tiene activada esa funcionalidad
        if (this.model.nif == null){
            
        }
        else if (tag === 'nif' && this.showRopoRomaRegepa && (this.model.nif as string).length >= 9  ) {
            firstValueFrom(this._ropoService.getROPO(this.model.nif))
                .then( (resp) => {
                    if (resp.ropo) {
                        this.model.ropo = resp.ropo.código;
                        this.model.fecha_cad = resp.ropo.caducidad;
                        this.model.tipo_carnet = resp.ropo.nivel.includes('cualificado') ? 'CUALIFICADO' : 'BÁSICO';
                    } 
                    return;
                })
                .catch( (error: string) => console.log('error', error)
                );
        }

    }

    // Sigpad
    public drawComplete(sigpad: SignaturePad) {
        this.modelSignature = sigpad.toDataURL('image/png');
    }
    public clearSigPad(sigpad: SignaturePad) {
        sigpad.clear();
        this.modelSignature = null;
    }
    public async fetchSignature() {
        try {
            const res = await fetch(this.urlSignature);
            if (res.status) {
                this.hasSignature = true;
                const blob = await res.blob();
                const urlCreator = window.URL;
                this.signaturePadElement?.fromDataURL(urlCreator.createObjectURL(blob));
            }
        } catch (e) {
            console.log('catch en getPosition: ' + e);
        }
    }

    public validatePassword() {
        this.showPassErrors = false;
        this.passErrors = '';
    
        if ((this.model.password || '').trim().length > 0) {
            if (!this.model.password_repeat) {
                this.passErrors += 'Por favor, repita la contraseña.\n';
                return false;
            }
            if (this.model.password !== this.model.password_repeat) {
                this.passErrors += 'Las contraseñas no coinciden.\n';
                return false;
            }
            this.fieldsToSend = this.fieldsToSend.concat('password');
            this.formRequest.setFieldsToSend(this.fieldsToSend);
            return true;
        }
        return false;
    }
    // Adjuntos
    public readUrl(input: HTMLInputElement) {
        input.click();
        if (input.files && input.files[0]) {
            const reader = new FileReader();
            reader.onload = (e) => {
                this.adjunto = e.target?.result;
            };
            reader.readAsDataURL(input.files[0]);
            const r = this.http.post(
                environment.serverUrl + 'ws/trabajadores/upload_file_trabajadores.php', this.adjunto).subscribe(res => {
                const file = res as FileUploadAnswer;
                this.model.adjunto = file.fileName;
                r.unsubscribe();
            });
        }
    }

    public fileChange(event: Event ) {
        const input = event.target as HTMLInputElement;

        if (input.files && input.files.length > 0) {
            const fileList: FileList = input.files;
            if (fileList.length > 0) {
                const file: File = fileList[0] ?? new File([],'');
                const formData: FormData = new FormData();
                formData.append('uploadFile', file, file.name);
                const headers = new HttpHeaders();
                headers.append('Content-Type', 'multipart/form-data');
                headers.append('Accept', 'application/json');

                const name: string[] = file.name.split('.');
                const ext = name[name.length - 1];
                const accepted = ['gif', 'jpeg', 'jpg', 'jiff', 'png', 'svg', 'tiff'];
                let cond = false;

                for (const e of accepted) {
                    if (e === ext) {
                        cond = true;
                    }
                }
                
                if (cond) {
                    this.http.post<FileUploadAnswer>(
                        `${environment.serverUrl}ws/trabajadores/upload_file_trabajadores.php`, 
                        formData, 
                        {headers: headers}
                    ).pipe(
                        map(data => {
                            const res = data || {};
                            this.model.adjunto = res.fileName;
                        }),
                        catchError(error => {
                            console.log(error);
                            return of(null);
                        })
                    ).subscribe();
                } else {
                    alert('Formato de imágen no válido.');
                    this.model.adjunto = '';
                }
            }
        }
    }

    public verAdjunto() {
        const myWindow = window.open('about:blank', '_blank', 'width=600, height=600');
        myWindow?.location.assign(environment.serverUrl + 'fotos_trabajadores/' + this.model.adjunto);
        myWindow?.focus();
    }

    private initFormData(): Promise<boolean> {
        const builds = [
            build(this.trabajadoresApi.maquinariaGET, this.maquinaria, 'nombre', true)
        ];
        if (this.showTrabajadoresCargos) {
            builds.push(build(this.trabajadoresApi.cargosGET, this.cargos, 'nombre'));
        }
        if (this.showTrabajadoresClienteAsignado) {
            builds.push(build(this.clientesApi.clientesGET, this.clientes, 'nombre'));
        }
        if (this.showTrabajadoresCuadrillas) {
            builds.push(
                build(
                    this.trabajadoresApi.cuadrillas.GET, 
                    this.cuadrillas, 
                    (it: MantenimientoModel) => it.nombre + ' ' + (it.apellidos || '')
                )
            );
        }
        if (this.appName === 'sirga') {
            builds.push(build(this.ventasApi.compradoresGET, this.comercios, 'razon_social', true));
        }
        return stack(builds, () => {
            this.formRequest.update();
        });
    }
}