import { Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DashboardService } from '../../../../service/events/dashboard.service';
import { HttpClient } from '@angular/common/http';
import { BaseView } from 'src/app/view/base-view';
import { TratamientosApiService } from 'src/app/service/api/tratamientos-api.service';
import { InputType } from 'src/app/common/components/form-builder/form-builder.component';
import { FincasApiService } from 'src/app/service/api/fincas-api.service';
import { list, stack, build } from 'src/app/common/classes/request-builder';
import { AppFormRequest } from 'src/app/view/app-common/form-request/app-form-request';
import { environment } from 'src/environments/environment';
import esLocale from '@fullcalendar/core/locales/es';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { RequestHandler } from 'src/app/service/OffService/request-handler';
import { RequestButtonTypes } from 'src/app/common/components/request-button/request-button.component';
import moment, { Moment } from 'moment';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import { TareasApiService } from 'src/app/service/api/tareas-api.service';
import { dialogTratamientoManezLozanoChartsComponent } from './dialog-tratamiento-manez/dialog-tratamiento-manez.component';
import { StorageManager } from 'src/app/common/storage-manager.class';
import tippy from 'tippy.js';

import type { OnInit, AfterViewInit } from '@angular/core';
import type { TratamientosControlModel } from 'src/app/models/tratamientos/tratamientos-control.model';
import type { ItemInterface } from 'src/app/common/components/form-builder/form-builder.component';
import type { DateClickArg } from '@fullcalendar/interaction';
import type { EventInput } from 'fullcalendar';
import { FincasModel } from 'src/app/models/form-common/fincas.module';
import { MaquinariaModel } from 'src/app/models/trabajadores/maquinaria.model';
import { TareasBioModel } from 'src/app/models/tareas/tareas-bio.model';
import { CalendarOptions, EventClickArg, EventMountArg } from '@fullcalendar/core';
import { BaseResponseModel } from 'src/base-response.model';
import { DropdownChangeEvent } from 'primeng/dropdown';
import { FullCalendarComponent } from '@fullcalendar/angular';
import { TypeAheadInterface } from 'src/app/common/components/form-builder/form-builder.component';
import { catchError, map } from 'rxjs/operators';
import { of } from 'rxjs';

moment.locale('es');
@Component({
    selector: 'app-control-tratamientos',
    templateUrl: 'control.component.html',
    styleUrls: ['./control.component.scss']
})
export class TratamientosControlComponent extends BaseView<TratamientosControlModel> implements OnInit, AfterViewInit {

    @ViewChild('dialogTratamientos', { static: false }) dialogTratamientos: dialogTratamientoManezLozanoChartsComponent | undefined;

    public fincaName = environment.features.fincaName;
    public appName = environment.appName;

    public getRequest: RequestHandler<TratamientosControlModel[]> = this.tratamientosApi.tratamientosControl.GET;
    public deleteRequest: RequestHandler<TratamientosControlModel> = this.tratamientosApi.tratamientosControl.DELETE;
    public getRequestBio: RequestHandler<TareasBioModel[]> = this.tareasApi.bio.GET;
    public pathToForm = 'tratamientos-control-form';
    public menuItemId = 'tratamientos-control';

    public deleteRequestButtonType: RequestButtonTypes = RequestButtonTypes.DELETE;

    public formRequest = new AppFormRequest<TratamientosControlModel>();
    public tratamientos: TratamientosControlModel[] = [];
    public tareasBio: TareasBioModel[] = [];
    public tareasBioArray: TareasBioModel[] = [];
    public tareasBioArrayFiltered: TareasBioModel[] = [];
    public tratamientosArray: TratamientosControlModel[] = [];
    public tratamientosAuxArray: TratamientosControlModel[] = [];
    public arrayEvents: TratamientosControlModel[] | TareasBioModel[] = [];
    public tratamientosParaImprimir: TratamientosControlModel[] | TareasBioModel[] = [];
    public showEliminarControl = false;
    public showImprimirControl = false;
    public showDescargarCsv = false;
    public isPrinting = false;
    public controlSeleccionado: TratamientosControlModel | undefined;
    public currentWeek = 0;
    public currentWeekArray: number[] = [];
    public currentDayMatrix: number[][] = [];
    public currentMonthAndYear = '';
    public fincas: TypeAheadInterface<FincasModel> = list(['...', null]);
    public fincaModel: FincasModel = {
        id: '',
        nombre: '',
        superficie: '',
        id_usuario: '',
        id_cliente: '',
        nombre_cliente: '',
        email: ''
    };
    public fincaSeleccionada = '';
    public fincasIsLoaded = false;
    loadingTelemetry = true;
    public csvModel: TratamientosControlModel = {};

    public dateValue = new Date();

    public maquinarias: {name: string, value: number | null}[] = [];
    public selectedMaquinarias: {label: ''; value: MaquinariaModel | null} = {label: '', value: null};

    public options: CalendarOptions = {
        plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
        locale: esLocale,
        height: 500,
        aspectRatio: 3,
        eventClick: (event: EventClickArg) => {
            this.irAlEvento(event);
        },
        dateClick: (dataEventClick: DateClickArg) => {
            this.irAlDia(dataEventClick);
        },
        eventDidMount: function (info: EventMountArg) {
            tippy(info.el, {
                content: info.event.extendedProps['description'],
                theme: 'light',
                trigger: 'hover',
                placement: 'top',
                interactive: true
            });
        }
    };
    public months = [
        { name: 'Enero', value: 1 },
        { name: 'Febrero', value: 2 },
        { name: 'Marzo', value: 3 },
        { name: 'Abril', value: 4 },
        { name: 'Mayo', value: 5 },
        { name: 'Junio', value: 6 },
        { name: 'Julio', value: 7 },
        { name: 'Agosto', value: 8 },
        { name: 'Septiembre', value: 9 },
        { name: 'Octubre', value: 10 },
        { name: 'Noviembre', value: 11 },
        { name: 'Diciembre', value: 12 }
    ];
    public years = [
        { name: '2020', value: 2020 },
        { name: '2021', value: 2021 },
        { name: '2022', value: 2022 },
        { name: '2023', value: 2023 },
        { name: '2024', value: 2024 },
        { name: '2025', value: 2025 },
        { name: '2026', value: 2026 },
        { name: '2027', value: 2027 },
        { name: '2028', value: 2028 },
        { name: '2030', value: 2030 }
    ];
    public selectedMonth: {name: string | null, value: string | null} = { name: null, value: null };
    public selectedYear: {name: string | null, value: string | null} = { name: null, value: null };
    public selectedDate = new Date();

    public formFieldsFinca: ItemInterface<object>[] = [
        {
            hasLabel: false,
            field: 'id_finca',
            inputType: {type: InputType.DROPDOWN_SEARCH},
            values: this.fincas,
            valuePrimaryKey: 'id'
        }
    ];
    public formFieldsCsv: ItemInterface<object>[] = [
        {
            label: 'Desde: ',
            field: 'fecha_inicio',
            inputType: {type: InputType.CALENDAR},
            visible: false
        },
        {
            label: 'Hasta: ',
            field: 'fecha_fin',
            inputType: {type: InputType.CALENDAR},
            visible: false
        },
        {
            label: this.fincaName,
            field: 'fincas',
            inputType: {type: InputType.DROPDOWN_SEARCH},
            values: this.fincas,
            valuePrimaryKey: 'id'
        }
    ];
    contadorMaquinaria = 0;

    constructor(
        private tratamientosApi: TratamientosApiService,
        private tareasApi: TareasApiService,
        private fincasApi: FincasApiService,
        private dashboard: DashboardService,
        private router: Router,
        public http: HttpClient
    ) {
        super(dashboard, tratamientosApi.tratamientosControl.GET, tratamientosApi.tratamientosControl.DELETE);
    }

    ngOnInit() {
        setTimeout(() => {
            this.filterFincas();
        }, 500);

        this.dashboard.setHeaderTitle('Calendario');
        this.dashboard.setMenuItem(this.menuItemId);

        if (this.appName === 'manezylozano') {

            this.options = {
                plugins: [dayGridPlugin, interactionPlugin],
                locale: esLocale,
                height: 500,
                aspectRatio: 3,
                editable: true,
                eventClick: (event: EventClickArg) => {
                    this.irAlEvento(event);
                },
                dateClick: (dataEventClick: DateClickArg) => {
                    this.irAlDia(dataEventClick);
                },
                datesSet: this.getDataManez.bind(this),
                eventDidMount: function () { }

            };
        } else {

            this.getTareasBio();
            this.fincaModel = JSON.parse(localStorage.getItem('CURRENT_TC_FINCA') ?? '');

            stack([
                build(this.fincasApi.fincasGET, this.fincas, 'nombre', true)
            ]).then(() => {
                if (this.fincaModel) {
                    const found = this.fincas.values
                        .find(it => it && it.value && ((it.value as FincasModel).id === this.fincaModel.id));
                    this.fincas.selected = found ? found.value : null;
                }
                this.filterFincas();
                this.formRequest.update();
                return;
            }).catch (e => {
                console.log('catch en stack: ' + e);
            }
            );
        }
        this.dateValue = new Date();
        this.getTratamientosControl();
        this.selectedMonth = JSON.parse(localStorage.getItem('CURRENT_TC_MONTH') || '{}');
        this.selectedYear = JSON.parse(localStorage.getItem('CURRENT_TC_YEAR') || '{}');
    }

    ngAfterViewInit() {
        const fcView: HTMLCollectionOf<Element> = document.getElementsByClassName('fc-view');
  
        const fcViewArray = Array.from(fcView);
      
        for (const e of fcViewArray) {
            e.classList.add('no-zindex');
        }
    }

    getDataManez(event: EventInput) {
        
        const rol = StorageManager.getUser().rol;
        const idUsuario = StorageManager.getUser().id;


        
        let month = moment(event.end).format('MM') as unknown as number - 1;

        let year = moment(event.start).format('YYYY') as unknown as number;
        const endYear = moment(event.end).format('YYYY') as unknown as number;

        if (month === 0) {
            month = 12;
        }

        if (year !== endYear && month === 1) {
            year = endYear;
        }

        this.http.get<BaseResponseModel<TratamientosControlModel>>(
            environment.serverUrl + 
            'ws/index.php?p1=tratamientos&p2=tratamientos_control_get_date&year=' 
            + year + 
            '&month=' + month + 
            '&rol=' + rol + 
            '&id_usuario=' + idUsuario
        )
            .pipe(map(response => {
                const data = response.data as TratamientosControlModel[];
                const aux: TratamientosControlModel[] = [];

                data.forEach(it => {
                    aux.push({
                        date: (moment(it.fecha)).format('YYYY-MM-DD'),
                        id_finca: it.id_finca ?? 0,
                        fecha: (moment(it.fecha)).format('DD-MM-YYYY'),
                        id_maquinaria: it.id_maquinaria as string,
                        id_usuario: it.id_usuario as string,
                        id_cliente: it.id_cliente as string,
                        ratio_ha: it.ratio_ha as string,
                        uds_medida: it.uds_medida as string,
                        title: it.observaciones ? it.observaciones : it.imei as string,
                        finca_nombre: it.finca_nombre as string,
                        imei: it.imei as string,
                        observaciones: it.observaciones as string,
                        description: it.observaciones as string
                    });
                });

                this.tratamientosArray = aux;
                this.loadingTelemetry = false;

                if (this.selectedMaquinarias.value) {
                    this.arrayEvents = this.tratamientosArray.filter(t => t.id_maquinaria === this.selectedMaquinarias.value);
                } else {

                    this.arrayEvents = this.removeDuplicates(this.tratamientosArray);
                }


            }), catchError(error => {
                this.loadingTelemetry = false;
                console.log(error);
                return of(null);  // Retorna un observable per gestionar l'error sense trencar el flux
            })).subscribe();
    }

    removeDuplicates(arr: TratamientosControlModel[]) {
        return arr.filter((item: TratamientosControlModel, index: number) => 
            arr.findIndex((otherItem: TratamientosControlModel) => 
                JSON.stringify(item.imei) === 
                    JSON.stringify(otherItem.imei) && 
                        item.date === otherItem.date && 
                        item.observaciones === otherItem.observaciones
            ) === index);
    }

    fillMaquinarias(values: TratamientosControlModel[]) {

        return new Promise(resolve => {

            this.maquinarias = [];
            if (values == null) {
                resolve(true);
                return;
            }

            if (values.length === 0) {
                resolve(true);
                return;
            }

            for (let index = 0; index < values.length; index++) {
                const element = values[index];

                if (element != null) {
                    if (element.id != null && element.title != null) {
                        this.maquinarias.push({ name: (element.title as string), value: parseInt(element.id.toString(), 10) });
                    }
                }

                if ((index + 1) >= values.length) {
                    this.maquinarias = Array.from(new Set(this.maquinarias.map(a => a.value)))

                        .map(value => this.maquinarias.find(a => a.value === value)) as {name: string, value: number | null}[];
                    this.maquinarias.unshift({ name: '...', value: null });
                    resolve(true);
                }

            }

            this.maquinarias = this.maquinarias.filter(a => a !== undefined);

        });

    }

    filterMaquinaria(event: DropdownChangeEvent) {

        const filter = event['value'];

        if (filter.value) {
            this.options.events = this.tratamientosArray.filter(t => t.id_maquinaria === filter.value).toString();
        } else {
            this.options.events = this.tratamientosArray.toString();
        }


    }


    public async filterTratamientosControl() {
        localStorage.setItem('CURRENT_TC_FINCA', JSON.stringify(this.fincaModel));

        if (this.fincaModel && this.fincaModel.id) {
            this.arrayEvents = this.tratamientosArray.filter(it => it.id_finca === +this.fincaModel.id);
            this.tareasBioArrayFiltered = this.tareasBioArray.filter(it => it.id_finca === this.fincaModel.id);
        } else {
            this.arrayEvents = this.tratamientosArray;
            this.tareasBioArrayFiltered = this.tareasBioArray;
        }


        if (this.appName === 'manezylozano') {
            this.tratamientosAuxArray = [...this.arrayEvents];
            await this.fillMaquinarias(this.tratamientosAuxArray ?? '');
            if (this.maquinarias.length > 1) {
                this.contadorMaquinaria++;

                if (this.contadorMaquinaria >= 1) {
                    this.loadingTelemetry = false;
                }

            }

            this.loadingTelemetry = false;
        } else {
            this.tareasBioArrayFiltered.map(it => (this.arrayEvents as TareasBioModel[]).push(it));
            this.loadingTelemetry = false;
        }
    }
    public filterTareasBio() {
        localStorage.setItem('CURRENT_TC_FINCA', JSON.stringify(this.fincaModel));

        if (this.fincaModel && this.fincaModel.id) {
            this.tareasBioArrayFiltered = this.tareasBioArray.filter(it => it.id_finca === this.fincaModel.id);
        } else {
            this.tareasBioArrayFiltered = this.tareasBioArray;
        }

    }

    public imprimirControl() {
        this.isPrinting = true;
        setTimeout(async () => {
            await html2canvas(document.getElementById('html2canvasDiv') ?? new HTMLElement(), { scale: 2, logging: false }).then(canvas => {
                const pdf = new jsPDF('l', 'mm', 'a4');
                const data = canvas.toDataURL('image/jpeg');
                pdf.addImage(data, 'jpg', 0, 0, 290, (canvas.height / (canvas.width / 290)), 'alias', 'FAST');
                pdf.save('CT ' + (this.fincas.selected as FincasModel).nombre + ' - ' + this.currentMonthAndYear);

                this.isPrinting = false;
                return;
            }).catch (e => {
                console.log('catch en getPosition: ' + e);
            }
            );
        }, 100);
    }

    public irAlDia(e: DateClickArg) {
        if (this.fincaModel && this.fincaModel.id) {
            this.router.navigate([
                'dashboard',
                'crear-control-tratamiento',
                this.fincaModel.id,
                moment(e.date).format('DD-MM-YYYY')
            ]);
        }
    }

    public irAlEvento(e: EventClickArg) {
        let idControl = e.event._def.publicId;

        if (this.appName === 'manezylozano') {
            idControl = e.event._def.publicId ? e.event._def.publicId : e.event._def.sourceId;
        }

        if (idControl) {
            if (e.event._def.extendedProps['prioridad'] === 'Bio') {
                this.router.navigate(['dashboard', 'editar-bio', idControl, true]);
            }
            else {
                if (this.appName === 'manezylozano') {
                    if (e.event._def.defId != null) {

                        const tratamiento: ControlMaquinarias = {
                            id: e.event._def.defId,
                            fecha: e.event._def.extendedProps['fecha'],
                            date: e.event._def.extendedProps['fecha'],
                            id_usuario: e.event._def.extendedProps['id_usuario'],
                            id_cliente: e.event._def.extendedProps['id_cliente'],
                            id_maquinaria: e.event._def.extendedProps['id_maquinaria'],
                            imei: e.event._def.extendedProps['imei'],
                            ratio_ha: e.event._def.extendedProps['ratio_ha'],
                            uds_medida: e.event._def.extendedProps['uds_medida'],
                            observaciones: e.event._def.extendedProps['observaciones']
                        };

                        this.dialogTratamientos?.opendialogChart(tratamiento);
                    }
                } else {
                    this.router.navigate(['dashboard', 'editar-control-tratamiento', idControl]);
                }
            }
        }
        for (const tratamiento of this.tratamientos) {
            if (tratamiento.id === idControl) {
                this.controlSeleccionado = tratamiento;

                break;
            }
        }
    }

    public crearControl() {
        this.router.navigate(['dashboard', 'crear-control-tratamiento']);
    }

    public editControl(data: TratamientosControlModel) {
        this.router.navigate(['dashboard', 'editar-control-tratamiento', data.id]);
    }

    public showEliminar() {
        this.showEliminarControl = false;

        this.deleteRequest.safePerform(this.controlSeleccionado);
        this.deleteRequest.response(() => {
            if (!this.deleteRequest.hasError) {
                this.getRequest.perform();
            }
        });
    }

    public showImprimir() {
        // Genera dinámicamente los números a mostrar en la tabla que se va a imprimir
        const currentDate = moment(this.selectedDate).startOf('month');
        let firstWeek = moment(currentDate).startOf('month').week();
        const lastWeek = moment(currentDate).endOf('month').week();
        const firstDaysWeekday = moment(currentDate).startOf('month').weekday();

        if ((lastWeek - firstWeek + 1) < 1) {
            firstWeek = 0;
        }

        this.currentWeek = moment(this.selectedDate).startOf('month').week();
        this.currentWeekArray = Array(lastWeek - firstWeek + 1).fill(1).map((_, index) => firstWeek + index);
        this.currentMonthAndYear = moment(this.selectedDate).format('MMMM YYYY').toUpperCase();

        let day1: Moment | number;
        let day7: number;
        let weekCount = 0;

        for (let week of this.currentDayMatrix) {
            day1 = moment(currentDate).subtract(firstDaysWeekday, 'days').add((7 * weekCount), 'days');
            day7 = moment(day1 || {}).add(6, 'days').date();
            day1 = day1.date();

            week = [];

            switch (weekCount) {
            case 0:
                if (day1 !== 1) {
                    while (day7 !== 0) {
                        week.unshift(day7--);
                    }

                    while ((week ?? []).length < 7) {
                        week.unshift(0);
                    }
                } else {
                    for (let d = day1; d <= day7; d++) {
                        week.push(d);
                    }
                }

                break;
            case ((this.currentWeekArray.length === 4) ? this.currentWeekArray.length : (this.currentWeekArray.length - 1)):
                if (day1 !== 25) {
                    const nullValues: number[] = [];
                    while (day7 !== 0) {
                        nullValues.push(0);
                        day7--;
                    }
                    while (week.length < 7 - nullValues.length) {
                        week.push(day1++);
                    }
                    week = week.concat(nullValues);
                } else {
                    for (let d = day1; d <= day7; d++) {
                        week.push(d);
                    }
                }

                break;
            default:
                for (let d = day1; d <= day7; d++) {
                    week.push(d);
                }
                break;
            }

            weekCount++;
        }

        this.tratamientosParaImprimir = [];

        this.currentDayMatrix.forEach(week => {
            week.forEach(day => {
                if (day !== null) {
                    (this.tratamientosParaImprimir as TratamientosControlModel[]).push(
                        this.arrayEvents.find((it) => {
                            const fecha = moment((it as TratamientosControlModel).start, 'YYYY-MM-DD');
                            const esteMes = moment(this.selectedDate).month();
                            const esteAnyo = moment(this.selectedDate).year();

                            return (
                                (parseInt(fecha.format('D'), 10) === day) &&
                                (fecha.month() === esteMes) &&
                                (fecha.year() === esteAnyo)
                            );
                        }) as TratamientosControlModel
                    );

                }
            });
        });
        this.currentDayMatrix.forEach(week => {
            week.forEach(day => {
                if (day !== null) {
                    (this.tratamientosParaImprimir as TareasBioModel[]).push(
                        this.tareasBioArrayFiltered.find((it) => {
                            const fecha = moment((it as TareasBioModel).start, 'YYYY-MM-DD');
                            const esteMes = moment(this.selectedDate).month();
                            const esteAnyo = moment(this.selectedDate).year();

                            return (
                                (parseInt(fecha.format('D'), 10) === day) &&
                                (fecha.month() === esteMes) &&
                                (fecha.year() === esteAnyo)
                            );
                        }) as TareasBioModel
                    );
                }
            });
        });


        const elements = document.getElementsByClassName('ui-dialog-content ui-widget-content');
        setTimeout(() => {
            if (elements[0]) {
                elements[0].scrollTop = 0;
            }
        }, 100);

        this.showImprimirControl = true;
    }

    public showSelectedDate(calendar: FullCalendarComponent) {
        localStorage.setItem('CURRENT_TC_MONTH', JSON.stringify(this.selectedMonth));
        localStorage.setItem('CURRENT_TC_YEAR', JSON.stringify(this.selectedYear));

        const day = new Date().getDate();
        let month = this.selectedMonth ? this.selectedMonth.value : new Date().getMonth() + 1;
        if ((month || '').toString().length === 1) {
            month = '0' + month;
        }
        const year = this.selectedYear ? this.selectedYear.value : new Date().getFullYear();
        const date = new Date(`${year}-${month}-${day}`);

        this.selectedDate = date;

        calendar.getApi().gotoDate(date.getTime());

        if (this.formFieldsFinca[0]) {
            (this.formFieldsFinca[0].values as TypeAheadInterface<FincasModel>).selected = null;
        }

        this.fincaSeleccionada = '';
        this.model.id_finca = 0;

        this.filterFincas();
    }

    public filterFincas() {
        if (this.formFieldsFinca[0]) {
            if (this.selectedYear && this.selectedYear.name) {
                (this.formFieldsFinca[0].values as TypeAheadInterface<FincasModel>).filtered
                    = (this.formFieldsFinca[0].values as TypeAheadInterface<FincasModel>).values
                        .filter((it) => {
                            if (!it.value) {
                                return true;
                            }
                            return ((it.value as FincasModel).nombre || '').includes(this.selectedYear.name ?? '');
                        });
            } else {
                (this.formFieldsFinca[0].values as TypeAheadInterface<FincasModel>).filtered = 
                    (this.formFieldsFinca[0].values as TypeAheadInterface<FincasModel>).values;
            }
            // this.filterTratamientosControl();
        }
    }

    public downloadCsv() {
        let csvContent =
            'data:text/csv;charset=utf-8,'
            + 'id;'
            + 'id_finca;'
            + 'ids_parcelas;'
            + 'ids_trabajador;'
            + 'ids_maquinaria;'
            + 'fecha;'
            + 'plagas;'
            + 'dosis;'
            + 'id_producto1;'
            + 'id_producto2;'
            + 'id_producto3;'
            + 'producto1;'
            + 'riqueza1;'
            + 'dosis1;'
            + 'dosis_cuaderno1;'
            + 'producto2;'
            + 'riqueza2;'
            + 'dosis2;'
            + 'dosis_cuaderno2;'
            + 'producto3;'
            + 'riqueza3;'
            + 'dosis3;'
            + 'dosis_cuaderno3;'
            + 'id_tratamiento;'
            + 'id_tarea;'
            + 'id_cliente;'
            + 'id_usuario;'
            + 'observaciones;'
            + 'estado;'
            + 'prioridad;'

            + 'producto;'
            + 'cultivo;'
            + 'dosis;'
            + 'fecha_receta;'
            + 'fecha_aplicacion;'
            + 'forma_aplicacion;'
            + 'm2;'
            + 'modo_accion;'
            + 'sector;'
            + 'suelta_m2;'
            + 'superficie_cultivada;'
            + 'variedad;'
            + 'cantidad_total_producto;'
            + 'parcela\n';

        let download = this.tratamientos;
        let biodownload = this.tareasBio;

        if (this.csvModel.fecha_inicio) {
            download = download.filter(it => moment(it.fecha, 'YYYY-MM-DD').isSameOrAfter(moment(this.csvModel.fecha_inicio)));
            biodownload = biodownload.filter(it => moment(it.fecha, 'YYYY-MM-DD').isSameOrAfter(moment(this.csvModel.fecha_inicio)));
        }
        if (this.csvModel.fecha_fin) {
            biodownload = biodownload.filter(it => moment(it.fecha, 'YYYY-MM-DD').isSameOrBefore(moment(this.csvModel.fecha_fin)));
        }

        download = download
            .filter(it => it.id_finca === +(this.fincas.selected as FincasModel).id)
            .filter(it => moment(it.fecha, 'YYYY-MM-DD').month() === (moment(this.dateValue).month()));

        biodownload = biodownload
            .filter(it => it.id_finca === (this.fincas.selected as FincasModel).id)
            .filter(it => moment(it.fecha_receta, 'YYYY-MM-DD').month() === (moment(this.dateValue).month()));

        for (const control of download) {
            for (const prop in control) {
                if (control[prop]) {
                    control[prop] += '';
                }
            }

            csvContent +=
                '"' + control.id + '";' +
                '"' + control.id_finca + '";' +
                '"' + control.ids_parcelas + '";' +
                '"' + control.id_trabajador + '";' +
                '"' + control.id_maquinaria + '";' +
                '"' + control.fecha + '";' +
                '"' + control.plagas + '";' +
                '"' + control.dosis + '";' +
                '"' + control.id_producto1 + '";' +
                '"' + control.id_producto2 + '";' +
                '"' + control.id_producto3 + '";' +
                '"";' +
                '"' + control.riqueza1 + '";' +
                '"' + control.dosis1 + '";' +
                '"' + control.dosis_cuaderno1 + '";' +
                '"' + control.producto2 + '";' +
                '"' + control.riqueza2 + '";' +
                '"' + control.dosis2 + '";' +
                '"' + control.dosis_cuaderno2 + '";' +
                '"' + control.producto3 + '";' +
                '"' + control.riqueza3 + '";' +
                '"' + control.dosis3 + '";' +
                '"' + control.dosis_cuaderno3 + '";' +
                '"' + control.id_tratamiento + '";' +
                '"' + control.id_tarea + '";' +
                '"' + control.id_cliente + '";' +
                '"' + control.id_usuario + '";' +
                '"' + control.observaciones + '";' +
                '"' + control.estado + '";' +
                '"' + control.prioridad + '";' +
                '"";' +
                '"";' +
                '"";' +
                '"";' +
                '"";' +
                '"";' +
                '"";' +
                '"";' +
                '"";' +
                '"";' +
                '"";' +
                '"";' +
                '"";' +
                '""\n';

        }

        for (const control of biodownload) {
            for (const prop in control) {
                if (control[prop]) {
                    control[prop] += '';
                }
            }

            csvContent +=
                '"' + control.id + '";' +
                '"' + control.id_finca + '";' +
                '"' + control.ids_parcelas + '";' +
                '"";' +
                '"";' +
                '"";' +
                '"";' +
                '"";' +
                '"";' +
                '"";' +
                '"";' +
                '"";' +
                '"";' +
                '"";' +
                '"";' +
                '"";' +
                '"";' +
                '"";' +
                '"";' +
                '"";' +
                '"";' +
                '"";' +
                '"";' +
                '"";' +
                '"";' +
                '"";' +
                '"";' +
                '"";' +
                '"";' +
                '"";' +
                '"' + control.producto + '";' +
                '"' + control.cultivo + '";' +
                '"' + control.dosis + '";' +
                '"' + control.fecha_receta + '";' +
                '"' + control.fecha_aplicacion + '";' +
                '"' + control.forma_aplicacion + '";' +
                '"' + control.m2 + '";' +
                '"' + control.modo_accion + '";' +
                '"' + control.sector + '";' +
                '"' + control.suelta_m2 + '";' +
                '"' + control.superficie_cultivada + '";' +
                '"' + control.variedad + '";' +
                '"' + control.cantidad_total_producto + '";' +
                '"' + control.parcela + '"\n';

        }





        const csvLink = document.getElementById('csvLink') as HTMLAnchorElement | null;

        if (csvLink) {
            csvLink.download = 'controles.csv';
            csvLink.href = encodeURI(csvContent);
            csvLink.click();
        }// if();
    }

    private getTareasBio() {
        const fechaActual = moment().toDate();

        this.getRequestBio.safePerform();
        this.getRequestBio.response(res => {

            this.tareasBio = res;
            this.tareasBioArray = this.tareasBio
                .map((it: TareasBioModel) => {
                    let color = '';
                    const fechaControl = moment(it.fecha_aplicacion, 'DD/MM/YYYY').toDate();
                    const estado = it.orden;
                    switch (estado) {
                    case 'Planificado':
                        if (fechaControl < fechaActual) {
                            color = '#ff9090';
                        } else {
                            color = '#4184CE';
                        }
                        break;
                    case 'Orden ficha':
                        color = '#FFB13B';
                        break;
                    case 'Aplicado':
                        color = '#256B2A';
                        break;
                    default:
                        color = '#4184CE';
                        break;
                    }
                    let title = '';
                    if (it.producto) { title += it.producto + '-'; }
                    if (it.modo_accion) { title += it.modo_accion + '-'; }
                    if (it.forma_aplicacion) { title += it.forma_aplicacion + '-'; }
                    if (it.cultivo) { title += it.cultivo + '-'; }
                    if (it.variedad) { title += it.variedad + '-'; }
                    let auxFecha;
                    if (it.fecha_receta) {
                        const auxFechaSplit = (it.fecha_receta as string).split('/');
                        auxFecha = auxFechaSplit[2] + '-' + auxFechaSplit[1] + '-' + auxFechaSplit[0];
                    }


                    return {
                        id: it.id,
                        id_finca: it.id_finca,
                        description: 'tarea bio',
                        title,
                        color,
                        estado,
                        start: auxFecha,
                        dosis1: '',
                        dosis2: '',
                        dosis3: '',
                        finca_nombre: '',
                        observaciones: '',
                        prioridad: 'Bio',
                        producto1: '',
                        producto2: '',
                        producto3: '',
                        riqueza1: '',
                        riqueza2: '',
                        riqueza3: '',
                        tratamiento_nombre: ''
                    };
                }) as TareasBioModel[];

        })
            .toPromise()
            .then(() => {
                this.filterTareasBio();
                return;
            }).catch (e => {
                console.log('catch en tareasBioArray: ' + e);
            }
            );
    }

    private getTratamientosControl() {
        const fechaActual = moment().toDate();

        if (this.appName === 'manezylozano') {
            // const month = (new Date().getMonth()) + 1;
            // const year = (new Date().getFullYear());
            // this.fillTratmientosManezYLozano(year, month);

        } else {
            this.getRequest.safePerform();

            this.getRequest.response(res => {
                this.tratamientos = res;
                this.tratamientosArray = this.tratamientos
                    .map((it: TratamientosControlModel) => {
                        let color = '';
                        const fechaControl = moment(it.fecha, 'DD/MM/YYYY').toDate();
                        switch (it.estado) {
                        case 'Planificado':
                            if (fechaControl < fechaActual) {
                                color = '#ff9090';
                            } else {
                                color = '#4184CE';
                            }
                            break;
                        case 'Orden ficha':
                            color = '#FFB13B';
                            break;
                        case 'Aplicado':
                            color = '#256B2A';
                            break;
                        default:
                            color = '#4184CE';
                            break;
                        }

                        let title = '';
                        if (it.plagas) { title += it.plagas + '-'; }
                        if (it.producto1) { title += it.producto1 + ' ' + (it.dosis1 || '') + ' ' + (it.riqueza1 || '') + '-'; }
                        if (it.producto2) { title += it.producto2 + ' ' + (it.dosis2 || '') + ' ' + (it.riqueza2 || '') + '-'; }
                        if (it.producto3) { title += it.producto3 + ' ' + (it.dosis3 || '') + ' ' + (it.riqueza3 || '') + '-'; }
                        if (it.observaciones) { title += it.observaciones; }
                        return {
                            id: it.id,
                            id_finca: it.id_finca,
                            title,
                            finca_nombre: it.finca_nombre,
                            tratamiento_nombre: it.tratamiento_nombre,
                            estado: it.estado,
                            prioridad: it.prioridad,
                            start: it.fecha,
                            color,
                            observaciones: it.observaciones,
                            description: it.observaciones,
                            producto1: it.producto1,
                            producto2: it.producto2,
                            producto3: it.producto3,
                            riqueza1: it.riqueza1,
                            riqueza2: it.riqueza2,
                            riqueza3: it.riqueza3,
                            dosis1: it.dosis1,
                            dosis2: it.dosis2,
                            dosis3: it.dosis3
                        };

                    }) as TratamientosControlModel[];
            })
                .toPromise()
                .then(async () => {
                    await this.filterTratamientosControl();
                    return;
                }).catch (e => {
                    console.log('catch en getPosition: ' + e);
                }
                );
        }

    }

}

interface ControlMaquinarias {
    fecha: string;
    date: string;
    id: string;
    id_cliente: string;
    id_maquinaria: string;
    id_usuario: string;
    imei: string;
    observaciones: string;
    ratio_ha: string;
    uds_medida: string;
    maquinaria?: { imei?: string };
    id_telemetry?: string;

}