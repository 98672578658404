import {Injectable} from '@angular/core';
import {ExpandableItem, MenuItemModel} from '../../models/menu-item';
import {Router} from '@angular/router';
import {Observable, Subject} from 'rxjs';
import {environment} from '../../../environments/environment';
import { StorageManager } from 'src/app/common/storage-manager.class';


@Injectable({
    providedIn: 'root'
})
export class DashboardService {

    public rol = StorageManager.getUser().rol;
    public userName = StorageManager.getUser().nombre;
    public userLastName = StorageManager.getUser().apellidos;
    public appName = environment.appName;
    public applicationType = environment.features.applicationType;
    public showEstadisticas = environment.features.showEstadisticas;
    public clientesName = environment.features.clientesName;
    public fincaName = environment.features.fincaName;
    public showClientesCrm = environment.features.showClientesCrm;
    public fincasName = environment.features.fincasName;
    public showTratamientosMenu = environment.features.showTratamientosMenu;
    public showTratamientosControl = environment.features.showTratamientosControl;
    public showTratamientosManezLozano = environment.features.showTratamientosManezLozano;
    public showTareasFitosanitarias = environment.features.showTareasFitosanitarias;
    public showTareasFertilizantes = environment.features.showTareasFertilizantes;
    public showControlRiego = environment.features.showControlRiego;
    public showTrabajadores = environment.features.showTrabajadores;
    public showUsuarios = environment.features.showUsuarios;
    public hasClients = environment.features.hasClients;
    public userCanSeeClientes = environment.features.userCanSeeClientes ? true : (this.rol === 'admin' || this.rol === 'responsable');
    public userCanSeeCostes = environment.features.userCanSeeCostes ? true : (this.rol === 'admin');
    public showRetoInBasic = environment.appName === 'melia' && this.userName === 'agrogibe';

    public clientChanges$ = new Subject<boolean>();
    public changeClientActive = true;
    public clientsDialogChanges$ = new Subject<boolean>();
    public profileDialogChanges$ = new Subject<boolean>();
    public menuItemChanges$ = new Subject<string>();

    public headerTitle = '';
    public lastMenuItemSelected: MenuItemModel = new MenuItemModel();
    public lastSubmenuItemSelected = {} as ExpandableItem;
    public menuItemData: MenuItemModel[] = [
        {id: 'inicio', icon: 'fa fa-home', text: 'Inicio', isSelected: false, visible: false},
        {id: 'usuarios', icon: 'fa fa-user', text: 'Usuarios', isSelected: false,
            visible: (this.showUsuarios && (this.rol === 'admin'))},
        {id: 'statistics', icon: 'fa fa-line-chart', text: 'Estadísticas', isSelected: false,
            visible: (this.showEstadisticas && (this.rol === 'admin'))},

        {id: 'clientes', icon: 'fa fa-users', text: this.clientesName, isSelected: false,
            visible: (this.hasClients && this.userCanSeeClientes) && !this.showClientesCrm},
        {id: 'cliente', icon: 'fa fa-users', text: this.clientesName, isSelected: false,
            visible: this.showClientesCrm , 
            expandable: [
                {id: 'clientes', fatherId: 'cliente', text: this.clientesName, isSelected: false},
                {id: 'crm', fatherId: 'cliente', text: 'CRM', isSelected: false},
                {id: 'crm-consultas', fatherId: 'cliente', text: 'Consultas', isSelected: false},
            ]
        },
        {
            id: 'fincas', icon: 'fa fa-building', text: this.fincasName, isSelected: false,
            visible: environment.features.hasFincas, expandable: [
                {id: 'finca', fatherId: 'fincas', text: this.fincasName, isSelected: false},
                {
                    id: 'sectores', 
                    fatherId: 'fincas', 
                    text: environment.features.sectoresName, 
                    isSelected: false,
                    visible: environment.features.showSectores
                },
                {
                    id: 'parcelas',
                    fatherId: 'fincas',
                    text: environment.features.parcelasName,
                    isSelected: false
                }
            ]
        },
        {
            id: 'campos',
            icon: 'fa fa-tree',
            text: 'Campos', 
            isSelected: false,
            visible: environment.features.showCampos
        },
        {
            id: 'analisis', 
            icon: 'fa fa-search', 
            text: 'Análisis', 
            isSelected: false, 
            visible: environment.features.showAnalisis
        },
        {
            id: 'seguimiento', icon: 'fa fa-calendar', text: 'Seguimiento', isSelected: false, 
            visible: environment.features.showVisitasSeguimiento || this.appName === 'viverospereira' ? true : false
        },
        {
            id: 'mapas-section', icon: 'fa fa-map-marker', text: 'Mapas', 
            isSelected: false, 
            visible: (
                (environment.features.showMapas && this.appName !== 'melia') 
            || (this.appName === 'melia' && this.userName.toLowerCase() === 'alejandro') ), 
            expandable: [
                {
                    id: 'mapas', 
                    fatherId: 'mapas-section', 
                    text: environment.features.parcelasName, 
                    isSelected: false, 
                    visible: environment.features.showMapaParcelas 
                },
                {
                    id: 'mapas-rutas', 
                    fatherId: 'mapas-section', 
                    text: 'Rutas', 
                    isSelected: false, 
                    visible: environment.features.showMapaRutas 
                },
                {
                    id: 'mapas-tratamientos', 
                    fatherId: 'mapas-section', 
                    text: 'Tratamientos', 
                    isSelected: false, 
                    visible: environment.features.showMapaTratamientos 
                },
                {
                    id: 'satelite', 
                    fatherId: 'mapas-section', 
                    text: 'Satélite', 
                    isSelected: false, 
                    visible: environment.features.showMapasSatelite  || 
                        (this.appName === 'manezylozano' &&
                        this.userName.toLowerCase() === 'quique' && 
                        this.userLastName.toLowerCase() === 'lozano')  
                },
                {
                    id: 'meteo', 
                    fatherId: 'mapas-section', 
                    text: 'Meteo', 
                    isSelected: false, 
                    visible: environment.features.showVentusky || 
                        (this.appName === 'manezylozano' && 
                            this.userName.toLowerCase() === 'quique' && 
                            this.userLastName.toLowerCase() === 'lozano') 
                }
            ]},
        {
            id: 'ventas-section',
            icon: this.appName === 'covidai' ? 'fa fa-user' : 'fa fa-eur',
            text: environment.features.ventasName,
            isSelected: false,
            //TODO visible: environment.features.showVentas || (this.appName != 'covidai' && !environment.features.forceHiddenVentas),
            visible: (environment.features.showVentas || (this.appName === 'salf'  && this.rol === 'admin')) ,
            expandable: [
                {id: 'ventas', fatherId: 'ventas-section', text: environment.features.ventasName, isSelected: false,
                    visible: environment.features.showVentas && this.appName !== 'covidai'},
                {
                    id: 'compradores', 
                    fatherId: 'ventas-section', 
                    text: this.appName === 'esparragosgranada' ? 
                        'Cooperativas' : 
                        this.appName === 'covidai' ? 
                            'Proveedores' : 
                            'Comercios', 
                    isSelected: false,
                    visible: environment.features.showVentas || this.appName === 'covidai'
                }
            ]
        },
        {
            id: 'recogidas',
            icon: 'fa fa-shopping-basket',
            text: 'Recogidas',
            isSelected: false,
            visible: environment.features.showRecogidas
        },
        {
            id: '_aplicadores',
            icon: 'fa fa-id-card',
            text: 'Aplicadores',
            isSelected: false, 
            visible: environment.features.hasAplicadores && environment.features.showAplicadoresMenu,
            expandable: [
                {id: 'maquinaria', fatherId: '_aplicadores', text: 'Maquinaria', isSelected: false},
                {id: 'fichajes', fatherId: '_aplicadores', text: 'Fichajes', isSelected: false,
                    visible: environment.features.showAplicadoresFichajes},
                {id: 'partes', fatherId: '_aplicadores', text: 'Partes', isSelected: false,
                    visible: environment.features.showAplicadoresPartes},
                {
                    id: 'mantenimiento',
                    fatherId: '_aplicadores',
                    text: environment.features.aplicadoresName,
                    isSelected: false,
                    visible: this.showTrabajadores
                },
                {id: 'ropo_roma_regepa', fatherId: '_aplicadores', text: 'Consultas', isSelected: false,
                    visible: environment.features.showConsultaRopo
                },                
            ]
        },
        {
            id: 'maquinaria',
            icon: 'fa fa-truck',
            text: 'Maquinaria',
            isSelected: false, 
            visible: environment.features.showMaquinariaMenu
        },
        {
            id: 'consultas',
            icon: 'fa fa-list-alt',
            text: 'Vademecum',
            isSelected: false,
            visible: environment.features.showVademecumMenu
        },
        {
            id: 'productos',
            icon: 'fa fa-flask',
            text: 'Productos',
            isSelected: false,
            visible: environment.features.hasProductos,
            expandable: [
                {id: 'productos-fitosanitarios', fatherId: 'productos', text: 'Fitosanitarios', isSelected: false},
                {
                    id: 'productos-fertilizantes', 
                    fatherId: 'productos', 
                    text: 'Fertilizantes', 
                    isSelected: false, 
                    visible: environment.features.showProductosFeritilizantes
                },
                {
                    id: 'productos-bio', 
                    fatherId: 'productos', 
                    text: 'Bio', 
                    isSelected: false, 
                    visible: environment.features.showProductosBio
                },
                {
                    id: 'productos-ganaderia', 
                    fatherId: 'productos', 
                    text: 'Ganadería', 
                    isSelected: false, 
                    visible: environment.features.showGanaderia
                },
                {
                    id: 'consultas', 
                    fatherId: 'productos', 
                    text: 'Vademecum', 
                    isSelected: false, 
                    visible: environment.features.showVademecumMenu === true ? false : true 
                }
            ]
        },
        {
            id: 'productos-s',
            icon: 'fa fa-flask',
            text: 'Productos',
            isSelected: false,
            visible: environment.features.showProductosSimple
        },
        {
            id: 'tratamientos',
            icon: 'fa fa-file-text',
            text: 'Tratamientos',
            isSelected: false,
            visible: this.showTratamientosMenu
        },
        {
            id: 'tratamientos-manez',
            icon: 'fa fa-file-text',
            text: 'Tratamientos',
            isSelected: false,
            visible: this.showTratamientosManezLozano
        },        
        {
            id: 'tratamientos-control',
            icon: 'fa fa-calendar',
            text: 'Calendario',
            isSelected: false,
            visible: this.showTratamientosControl
        },
        { 
            id: 'calendario-tareas', 
            icon: 'fa fa-calendar-check-o', 
            text: 'Calendario',
            isSelected: false,
            visible: environment.features.showCalendarioTareas
        },
        {
            id: 'tareas',
            icon: 'fa fa-list-ul',
            text: 'Tareas',
            isSelected: false,
            visible: this.showTareasFitosanitarias || this.showTareasFertilizantes,
            expandable: [
                {id: 'fitosanitarios', fatherId: 'tareas', text: 'Fitosanitarias', isSelected: false,
                    visible: this.showTareasFitosanitarias},
                {id: 'fertilizantes', fatherId: 'tareas', text: 'Fertilizantes', isSelected: false,
                    visible: this.showTareasFertilizantes},
                {id: 'control-riego', fatherId: 'tareas', text: 'Control Riego', isSelected: false,
                    visible: this.showControlRiego},
                {id: 'calendario-abonado', fatherId: 'tareas', text: 'Abonado', isSelected: false,
                    visible: environment.features.showCalendarioAbonado},
                {id: 'bio', fatherId: 'tareas', text: 'Bio', isSelected: false, visible: environment.features.showTareasBio},
                {id: 'tareas-otros', fatherId: 'tareas', text: 'Genéricas', isSelected: false,
                    visible: environment.features.showTareasOtros},
                {id: 'tareas-siembra', fatherId: 'tareas', text: 'Siembra', isSelected: false,
                    visible: environment.features.showTareasSiembra},
                {id: 'control-sanitario', fatherId: 'tareas', text: 'Sanitario', isSelected: false,
                    visible: environment.features.showControlSanitario},
                {id: 'fito-locales', fatherId: 'tareas', text: 'Fito Locales', isSelected: false,
                    visible: environment.features.showFitoLocales},
                {id: 'cesped-cavado', fatherId: 'tareas', text: 'Césped/Cavado', isSelected: false,
                    visible: environment.features.showTareasMto},
                {id: 'poda', fatherId: 'tareas', text: 'Podas', isSelected: false,
                    visible: environment.features.showTareasMto},
                {id: 'limpieza-general', fatherId: 'tareas', text: 'Limpieza Gral.', isSelected: false,
                    visible: environment.features.showTareasMto},
                {id: 'riego', fatherId: 'tareas', text: 'Riego', isSelected: false,
                    visible: environment.features.showTareasMto},
                {id: 'piscinas', fatherId: 'tareas', text: 'Piscinas', isSelected: false,
                    visible: environment.features.showTareasMto},
                {id: 'plantacion', fatherId: 'tareas', text: 'Plantación', isSelected: false,
                    visible: environment.features.showTareasMto},
                {id: 'alquiler-maquinaria', fatherId: 'tareas', text: 'Alquiler', isSelected: false,
                    visible: environment.features.showTareasMto},
                {id: 'edificios', fatherId: 'tareas', text: 'Edificios', isSelected: false,
                    visible: environment.features.showTareasMto},
                { id: 'tareas-agroambientales', fatherId: 'tareas', text: 'Agroambientales', isSelected: false,
                    visible: environment.features.showTareasAgroambiental},
                {
                    id: 'subcontratos', 
                    fatherId: 'tareas', 
                    text: 'Subcontratos', 
                    isSelected: false, 
                    visible: environment.features.showSubcontrato
                },
                {id: 'tareas-ecorregimenes', fatherId: 'tareas', text: 'Ecorregímenes', isSelected: false, 
                    visible: environment.features.showEcorregimenes}
            ]
        },
        {id: 'visitas-pedidos', icon: 'fa fa-shopping-cart', text: 'Pedidos', isSelected: false,
            visible: environment.appName === 'agroleza'},
        {
            id: 'calendario', icon: 'fa fa-calendar', text: 'Visitas', isSelected: false, expandable: [
                {id: 'visitas', fatherId: 'calendario', text: 'Agenda', isSelected: false,
                    visible: environment.features.showVisitasAgenda},
                {id: 'visitas-consultas', fatherId: 'calendario', text: 'Consultas', isSelected: false,
                    visible: environment.features.showVisitasConsultas},
                {id: 'visitas-variedad-mes', fatherId: 'calendario', text: 'Visitas Mes', isSelected: false,
                    visible: environment.features.showVisitasVariedadMes},
            ],
            visible: environment.features.showVisitas && !(this.appName === 'agroleza' && StorageManager.getUser().tipo === 'comunero')
        },
        {
            id: 'visitas-semana', icon: 'fa fa-calendar', text: 'Visitas', isSelected: false, expandable: [
                {id: 'visitas-semana', fatherId: 'calendario', text: 'Visitas Semana', isSelected: false},
                {id: 'visitas-trasiego', fatherId: 'calendario', text: 'Visitas Trasiego', isSelected: false}
            ],
            visible: environment.features.showVisitasPorSemana
        },
        {
            id: 'costes', icon: 'fa fa-eur', text: 'Costes', isSelected: false,
            visible: environment.features.showCostes && this.userCanSeeCostes, expandable: [
                {
                    id: 'costes-tareas', 
                    fatherId: 'costes', 
                    text: 'Costes > Tareas', 
                    subtitulo: 'Tareas', 
                    isSelected: false, 
                    visible: environment.features.showCostesOtros
                },
                {
                    id: 'costes-productos', 
                    fatherId: 'costes', 
                    text: 'Costes > Productos', 
                    subtitulo: 'Productos', 
                    isSelected: false, 
                    visible: environment.features.showCostesProductos
                },
                {
                    id: 'costes-fitosanitarios',
                    fatherId: 'costes', text: 'Fitosanitarios',
                    isSelected: false,
                    visible: environment.features.showCostesFitosanitarios
                },
                {
                    id: 'costes-fertilizantes',
                    fatherId: 'costes',
                    text: 'Fertilizantes',
                    isSelected: false,
                    visible: environment.features.showCostesFertilizantes
                }
            ]
        },
        {
            id: 'stock',
            icon: 'fa fa-folder',
            text: 'Stock',
            isSelected: false,
            visible: environment.features.showStock,
            expandable: [
                {id: 'stock-entradas', fatherId: 'stock', text: 'Entradas/Salidas', isSelected: false},
                {
                    id: 'stock-balances', 
                    fatherId: 'stock', 
                    text: 'Balances', 
                    isSelected: false, 
                    visible: this.appName !== 'agropecuariaramirogruas'
                },
            ]
        },
        {
            id: 'reto',
            icon: 'fa fa-folder',
            text: 'RETO',
            isSelected: false,
            visible: environment.features.showReto || this.showRetoInBasic,
            expandable: [
                {id: 'reto-entidades', fatherId: 'reto', text: 'Entidades', isSelected: false},
                {id: 'reto-transacciones', fatherId: 'reto', text: 'Transacciones', isSelected: false},
            ]
        },
    ];


    constructor(private router: Router) {
        
        //console.log(this.hasClients);
        //console.log(environment.features.hasClients);
        //console.log(this.userCanSeeClientes);
        //console.log(environment.features.userCanSeeClientes);
        
    }

    public setMenuItem(menuItemId: string) {
        const menuItem = this.getMenuItem(menuItemId)[0];

        if (menuItem && !menuItem.isSelected) {
            this.unSelectLastMenuItem();
            this.unSelectLastSubmenuItem();

            this.selectMenuItem(menuItem);

            if (this.isMenuExpandable(menuItem)) {
                const submenu = menuItem.expandable?.[0];
                this.selectSubmenuItem(submenu?.id ?? '');
                this.setHeaderTitle(submenu?.text ?? '');
                this.router.navigate(['dashboard', submenu?.id]);
            } else {
                this.setHeaderTitle(menuItem.text);
                this.router.navigate(['dashboard', menuItemId]);
            }
        }
    }

    public setSubmenuItem(submenuItemId: string, doNavigation: boolean = true) {
        const submenuItem = this.getSubmenuItem(submenuItemId);

        if (submenuItem) {
            if (!submenuItem.father?.isSelected) {
                this.unSelectLastSubmenuItem();
                this.unSelectLastMenuItem();
                this.selectMenuItem(submenuItem.father ?? {} as MenuItemModel);
            }
            
            this.unSelectLastSubmenuItem();
            this.selectSubmenuItem(submenuItem.id);

            submenuItem.father?.expandable?.forEach(submenu => {
                submenu.isSelected = false;
            });
            submenuItem.isSelected = true;

            this.setHeaderTitle(submenuItem.text);
            if (doNavigation) {
                this.router.navigate(['dashboard', submenuItemId]);
            }
        }
    }

    public setHeaderTitle(headerTitle: string) {
        this.headerTitle = headerTitle;
    }

    public onMenuItemClick(menuItemId: string) {
        this.setMenuItem(menuItemId);
        this.menuItemChanges$.next(menuItemId);
    }

    public onSubmenuItemClick(submenuItemId: string) {
        this.setSubmenuItem(submenuItemId);
        this.menuItemChanges$.next(submenuItemId);
    }

    public onChangeClient(): Observable<boolean> {
        return this.clientChanges$.asObservable();
    }

    public emitClientChange() {
        this.clientChanges$.next(true);
    }

    public onDialogChanges(): Observable<boolean> {
        return this.clientsDialogChanges$.asObservable();
    }

    public openClientDialog() {
        this.clientsDialogChanges$.next(true);
    }

    public closeClientDialog() {
        this.clientsDialogChanges$.next(false);
    }

    public onProfileChanges(): Observable<boolean> {
        return this.profileDialogChanges$.asObservable();
    }

    public openProfileDialog() {
        this.profileDialogChanges$.next(true);
    }

    public closeProfileDialog() {
        this.profileDialogChanges$.next(false);
    }
    
    private unSelectLastMenuItem() {
        if (this.lastMenuItemSelected) {
            this.lastMenuItemSelected.isSelected = false;
        }
    }

    private unSelectLastSubmenuItem() {
        if (this.lastSubmenuItemSelected) {
            this.lastSubmenuItemSelected.isSelected = false;
        }
    }

    private selectMenuItem(menuItem: MenuItemModel) {
        this.lastMenuItemSelected = menuItem;
        menuItem.isSelected = true;
    }

    private selectSubmenuItem(submenuId: string) {
        const father = {} as MenuItemModel;

        if (father === this.findFather(submenuId)) {
            if (father.expandable && father.expandable.length > 0) {
                father.expandable.filter((submenu: ExpandableItem) => submenu.id === submenuId)
                    .forEach((submenu: ExpandableItem) => {
                        submenu.isSelected = true;
                        this.lastSubmenuItemSelected = submenu;
                    });
            }
        }
    }

    private isMenuExpandable(menuItem: MenuItemModel): boolean {
        return (menuItem.expandable && menuItem.expandable.length > 0) ?? false;
    }

    private getMenuItem(menuItemId: string): MenuItemModel[] {
        if (this.menuItemData) {
            return this.menuItemData.filter(i => i.id === menuItemId) || [];
        } else {
            return [];
        }
    }

    private getSubmenuItem(submenuItemId: string) {
        const father: MenuItemModel | null = this.findFather(submenuItemId);

        if (father && father.expandable && father.expandable.length > 0) {
            return father.expandable
                .filter((submenu: ExpandableItem) => {
                    submenu.father = father;
                    return submenu.id === submenuItemId;
                })[0];
        } else {
            return null;
        }

    }

    private findFather(id: string): MenuItemModel | null {
        let founded = false;
        let father: MenuItemModel | null = null;
        this.menuItemData.forEach(menu => {
            if (!founded && menu.expandable) {
                menu.expandable.forEach(submenu => {
                    if (submenu.id === id) {
                        founded = true;
                        father = menu;
                    }
                });
            }
        });

        return father;
    }
}
