import {Component, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {DashboardService} from '../../../../../service/events/dashboard.service';
import {InputType} from '../../../../../common/components/form-builder/form-builder.component';
import {BaseForm} from '../../../../base-form';
import {VentasApiService} from '../../../../../service/api/ventas-api.service';
import {FincasApiService} from '../../../../../service/api/fincas-api.service';
import {FormCommonApiService} from '../../../../../service/api/formcommon-api.service';
import {AppFormRequest} from '../../../../app-common/form-request/app-form-request';
import {build, list, pool} from '../../../../../common/classes/request-builder';
import {Utils} from '../../../../../common/utils';
import {StorageManager} from '../../../../../common/storage-manager.class';
import { environment } from '../../../../../../environments/environment';
import { RequestButtonComponent } from '../../../../../common/components/request-button/request-button.component';
import { ClientesApiService } from '../../../../../service/api/clientes-api.service';
import { FormRequestTypes } from '../../../../../common/classes/form-request';
import { Filtering } from '../../../../../service/filtering/filtering';
import { SiexApiService } from '../../../../../service/api/siex-api.service';

import type { OnInit } from '@angular/core';
import type { 
    FilteredValuesInterface, 
    ItemInterface, 
    TypeAheadInterface 
} from '../../../../../common/components/form-builder/form-builder.component';
import { SectorModel } from 'src/app/models/form-common/sector.model';
import { ParcelasModel } from 'src/app/models/form-common/parcelas.model';
import { Compradores } from 'src/app/models/ventas/compradores.model';
import { VentaModel } from 'src/app/models/ventas/venta.model';
import { VisitasVariedadMesModel } from 'src/app/models/visitas_variedad_mes/visitas_variedad_mes.model';
import { FincasModel } from 'src/app/models/form-common/fincas.module';
import { ClienteModel } from 'src/app/models/cliente.model';

const ventaName = environment.features.ventaName;

@Component({
    selector: 'app-nueva-carga',
    templateUrl: './ventas-form.component.html',
    styleUrls: ['./ventas-form.component.scss']
})
export class VentasFormComponent extends BaseForm<VentaModel> implements OnInit {

    @ViewChild(RequestButtonComponent, { read: RequestButtonComponent, static: true })
        requestButton = new RequestButtonComponent<VentaModel>();

    public appName = environment.appName;
    public ventaName = environment.features.ventaName;
    public sectorName = environment.features.sectorName;
    public parcelasName = environment.features.parcelasName;
    public tipoSuperficieParcelas = environment.features.hasSuperficieCultivada
        ? 'S.\xa0C.'
        : 'S.\xa0Sigpac';
    public showClientesCooperativa = environment.features.showClientesCooperativa;
    public showVentasComercio = environment.features.showVentasComercio;
    public textoSuperficie = environment.features.textoSuperficie;
    public activateApiSIEX = environment.features.activateSIEX;
    public hasClients = environment.features.hasClients;
    public userCanSeeClientes = this.hasClients &&
        (environment.features.userCanSeeClientes ? true : (StorageManager.getUser().rol === 'admin'));


    public formRequest = new AppFormRequest<VentaModel>();
    public override model: VentaModel = {};

    public distributionOrder = [
        3, 3, 3
    ];

    public fincas: TypeAheadInterface<FincasModel> = list();
    public sectores: TypeAheadInterface<SectorModel> = list();
    public parcelas: TypeAheadInterface<ParcelasModel | ParcelasModel[]> = list();
    public variedades: TypeAheadInterface<VisitasVariedadMesModel> = list();
    public compradores: TypeAheadInterface<Compradores> = list();
    public clientes: TypeAheadInterface<ClienteModel> = list();
    public clientes_comercios: TypeAheadInterface<Compradores> = list();
    public cooperativas: TypeAheadInterface<string> = list();
    public showVentasLote = environment.features.showVentasLote;

    public formFields: ItemInterface<object>[] = [
        // {
        //     field: 'cooperativa',
        //     label: 'Cooperativa',
        //     inputType: {type: InputType.DROPDOWN},
        //     values: this.cooperativas,
        //     required: true
        // },
        {
            field: 'id_finca',
            label: 'Finca',
            inputType: {type: InputType.DROPDOWN},
            required: true,
            values: this.fincas,
            valuePrimaryKey: 'id',
            filter: (it: FilteredValuesInterface<FincasModel>) => {
                if (!this.hasClients  || !this.userCanSeeClientes) {
                    if (StorageManager.getUser().tipo === 'comunero') {
                        console.log(it.value);
                        if (it.value?.nombre === 'PREFACIT') {
                            it.value.id_cliente = '9';
                        }
                        return it.value?.id_cliente ===  StorageManager.getUser().id_cliente;
                    } else {
                        return true;
                    }
                } else if (StorageManager.getClient()) {
                    return it.value?.id_cliente === (StorageManager.getClient() || {}).id;
                }
                return true;
            },
        },
        {
            field: 'id_sector',
            label: this.sectorName,
            inputType: {type: InputType.DROPDOWN},
            values: this.sectores,
            valuePrimaryKey: 'id',
            filter: (it: FilteredValuesInterface<SectorModel>) => it.value?.id_finca === (this.fincas.selected || {}).id
        },
        {
            field: 'ids_parcelas',
            label: this.parcelasName,
            inputType: {type: InputType.DROPDOWN_MULTISELECT_SEARCH},
            canSelectAll: true,
            required: this.appName === 'pyf' ? false : true,
            values: this.parcelas,
            valuePrimaryKey: 'id',
            multiSelect: true,
            filter: (it: FilteredValuesInterface<ParcelasModel>) => {
                if (this.sectores.selected) {
                    return it.value?.id_sector === (this.sectores.selected || {}).id
                        && it.value?.id_finca === (this.fincas.selected || {}).id;
                } else {
                    return it.value?.id_finca === (this.fincas.selected || {}).id;
                }
            }
        },
        {
            field: 'fecha', 
            label: 'Fecha', 
            inputType: {type: InputType.CALENDAR}
        },
        {
            field: 'albaran', 
            label: 'Nº Albarán', 
            inputType: {type: InputType.EDIT_TEXT}
        },
        {
            field: 'lote', 
            label: 'Nº Lote', 
            inputType: {type: InputType.EDIT_TEXT}, 
            visible: this.showVentasLote
        },
        {
            field: 'id_comprador',
            label: this.appName === 'esparragosgranada' ? 'Cooperativa' : 'Comercio',
            inputType: {type: InputType.DROPDOWN_SEARCH},
            values: this.compradores,
            valuePrimaryKey: 'id',
            visible: this.showVentasComercio && 
                this.appName !== 'esparragosgranada' && 
                this.appName !== 'covidai'
        },
        {
            field: 'id_comprador',
            label: 'Clientes',
            inputType: {type: InputType.DROPDOWN},
            values: this.clientes,
            valuePrimaryKey: 'id',
            visible: this.appName  === 'covidai',
            
        },
        {
            field: 'id_comprador',
            label: this.appName === 'esparragosgranada' ? 'Cooperativa' : 'Comercio',
            inputType: {type: InputType.DROPDOWN},
            values: this.compradores,
            valuePrimaryKey: 'id',
            visible: this.showVentasComercio && this.appName === 'esparragosgranada',
            filter: (it: FilteredValuesInterface<Compradores>) => {
                const cooperativa = this.appName === 'esparragosgranada' ? StorageManager.getClient()['cooperativa'] : '';
                if ( it.label.localeCompare(cooperativa) === 0 && cooperativa !== '' ) {
                    if ( this.model.id_comprador != null ) {

                    } else {
                        this.compradores.selected = it.value;
                    }
                }
                return true;
                
            }
        },
        {
            field: 'producto', 
            label: 'Producto', 
            inputType: {type: InputType.EDIT_TEXT}, 
            visible: this.appName === 'pyf'
        },
        {
            field: 'id_variedad',
            label: 'Variedad',
            inputType: {type: InputType.DROPDOWN},
            values: this.variedades,
            valuePrimaryKey: 'id',
            visible: this.appName !== 'pyf',
            filter: (it: FilteredValuesInterface<VisitasVariedadMesModel>) => {
                this.parcelasAux = ((this.parcelas.values || [])
                    .filter(p => p && p.value)
                    .map(p => p.value)
                    .filter(p => +(this.parcelas.selected as ParcelasModel[] || []).length > 0
                        ? (this.parcelas.selected as ParcelasModel[]).find((s: ParcelasModel) => s.id === (p as ParcelasModel).id)
                        : true
                    )
                    .filter(p => this.sectores.selected
                        ? (p as ParcelasModel).id_sector === this.sectores.selected.id
                        : (p as ParcelasModel).id_finca === this.model.id_finca)) as ParcelasModel[];


                if (environment.features.activateSIEX && !environment.features.hasPlantas) {
                    return this.parcelasAux.some(p => p.id_variedad === it.value?.id);                    
                } else {
                    return this.parcelasAux.some(p => p.id_variedad_legacy === it.value?.id);
                }
            }
        },
        {
            field: 'cantidad', 
            label: 'Cantidad Kg', 
            inputType: {type: InputType.EDIT_TEXT}, 
            required: true 
        },
        {
            field: 'precio', 
            label: 'Precio', 
            inputType: {type: InputType.EDIT_TEXT} 
        },
        {
            field: 'precio_kg', 
            label: this.appName === 'fotoagricultura' ? 
                'Precio / Kg' : 
                this.appName === 'agrosol' ? 
                    'Importe Parcial' : 
                    'Importe', 
            inputType: {type: InputType.EDIT_TEXT} 
        },
        {
            field: 'descuento_porcentaje', 
            label: 'Descuento %', 
            inputType: {type: InputType.EDIT_NUMERIC},
            visible: this.appName === 'agrosol'
        },
        {
            field: 'descuento_euros', 
            label: 'Descuento €', 
            inputType: {type: InputType.EDIT_NUMERIC},
            visible: this.appName === 'agrosol'
        },
        {
            field: 'importe_total', 
            label: 'Importe Total', 
            inputType: {type: InputType.EDIT_NUMERIC},
            visible: this.appName === 'agrosol'
        },
        {
            field: 'matricula', 
            label: 'Matricula', 
            inputType: {type: InputType.EDIT_TEXT}, 
            visible: this.appName === 'campanaragricola'
        }
    ];

    private parcelasAux: ParcelasModel[] = [];

    constructor(
        public override route: ActivatedRoute,
        public override dashboard: DashboardService,
        public clientesApi: ClientesApiService,
        public ventasApi: VentasApiService,
        public fincasApi: FincasApiService,
        public formApi: FormCommonApiService,
        public siexApi: SiexApiService,
        public override router: Router
    ) {
        super(
            route,
            router,
            dashboard,
            ventasApi.ventasPUT,
            ventasApi.ventasPOST,
            'ventas',
            'Actualizar ' + ventaName,
            'Crear ' + ventaName
        );
    }

    ngOnInit() {
        this.initFormData();

        this.formRequest
            .setType(this.getType())
            .setRegisterId(this.getRegisterId())
            .setModel(this.model)
            .isGeneric(false)
            .setGetRequest(this.ventasApi.ventasGET)
            .setPostRequest(this.ventasApi.ventasPOST)
            .setPutRequest(this.ventasApi.ventasPUT)
            .setFormFields(this.formFields)
            .setFieldsToSend((['id', 'id_cultivo', 'cultivo', 'variedad'])
                .concat(
                    this.formFields.map(it => it.field ?? '')
                )
            );

        this.formRequest.beforeSend(resolve => {
            this.model.id_cultivo = this.model.id_variedad ?? '';

            this.model.fecha = this.model.fecha
                ? Utils.formatDate(this.model.fecha)
                : this.model.fecha ?? '';

            this.model.cantidad = this.model.cantidad
                ? this.model.cantidad
                : 0;

            this.model.cultivo = (this.parcelas.selected as ParcelasModel[]).map((it: ParcelasModel) => it.cultivo).join(';');
            this.model.variedad = (this.parcelas.selected as ParcelasModel[]).map((it: ParcelasModel) => it.variedad).join(';');

            const clientId = (StorageManager.getClient() || {}).id;
            const clientIdFromFinca = (this.fincas.selected || {}).id_cliente;
            const clientIdFromForm = this.model['id_cliente'];

            this.model.id_cliente =
                clientIdFromFinca ? clientIdFromFinca :
                    clientIdFromForm ? clientIdFromForm :
                        clientId ? clientId : ''; // DEFAULT

            const userId = StorageManager.getUser().id;
            const userIdFromFinca = (this.fincas.selected || {}).id_usuario;
            const userIdFromForm = this.model.id_usuario;

            this.model.id_usuario =
                userIdFromFinca ? userIdFromFinca :
                    userIdFromForm ? userIdFromForm :
                        userId ? userId : '';

            if (this.appName !== 'covidai'){
                this.model.id_comprador = this.showVentasComercio ? 
                    (this.compradores.selected ? this.compradores.selected.id : null) 
                    : null;
            }

            resolve(true);
        });

        this.formRequest.load();
        if ( this.appName === 'ava' && this.getType() === 0 ) {
            this.fincasApi.fincasGET.toPromise().then( (resp_fincas: FincasModel[]) => {
             
                if (resp_fincas.length > 0 ) {
                    this.model.id_finca = resp_fincas[0]?.id ?? '';
                }
                return;
            }).catch (e => {
                console.log('catch en getPosition: ' + e);
            }
            );
        }
        this.softInit(this.getType());
        this.expandFormRequest();
    }

    public expandFormRequest(){
        this.formRequest.afterLoad((resolve) => {
            const fincaInterval = setInterval(() => {
              
              
                if ( this.fincas.filtered.length === 2  && this.getType() === 0 ){
                    
                    this.model.id_finca = this.fincas.filtered[1]?.value?.id ?? '';
                    clearInterval(fincaInterval);
                }
              
            }, 1000);
            resolve(true);
        });

        setTimeout(() => {
            this.countSuperficieParcelas();
        }, 1000);
    }

    public submit() {
        delete this.model.parcelas_superficie;

        if (this.appName !== 'agrosol') {
            delete this.model.descuento_porcentaje;
            delete this.model.descuento_euros;
            delete this.model.importe_total;
        }
        
        if (!this.formRequest.checkIfValid()) {
            this.requestButton.error = 'Hay campos obligatorios';
        }

        this.model.cantidad = +(this.model.cantidad?.toString().replace(',', '.') || '');        
        this.formRequest.send();
        
        if ((this.getType() === FormRequestTypes.DUPLICATE)){
            this.router.navigate(['dashboard', this.pathToGoBack]);
        }
    }

    public formChanges(tag: string) {
  
        
        if (tag === 'id_finca') {
            this.model.id_sector = null;
            this.model.ids_parcelas = null;
            this.model.id_variedad = null;
        }
        if (tag === 'id_sector') {
            this.model.ids_parcelas = null;
            this.model.id_variedad = null;
        }
        if (tag === 'ids_parcelas') {
            this.model.id_variedad = null;
        }
        if (this.appName !== 'fotoagricultura' && 
            ( (tag === 'cantidad' && this.model.precio) || 
              (tag === 'precio' && this.model.cantidad) )) {
            this.model.precio_kg = 
                +(this.model.cantidad?.toString().replace(',', '.') || '') * +(this.model.precio?.replace(',', '.') || '');
        }
        if (this.appName === 'agrosol' && 
            ( (tag === 'descuento_porcentaje' && this.model.descuento_porcentaje) || 
              (tag === 'precio_kg' && this.model.precio_kg) )) {
            this.model.descuento_euros = 
                +(this.model.precio_kg?.toString().replace(',', '.') || '') * 
                (+(this.model.descuento_porcentaje?.toString().replace(',', '.') || '') / 100);
            this.model.importe_total = 
                +(this.model.precio_kg?.toString().replace(',', '.') || '') -
                (+(this.model.descuento_euros?.toString().replace(',', '.') || ''));
        }

        this.countSuperficieParcelas();
        this.requestButton.error = '';
    }

    public selectAll() {
        this.countSuperficieParcelas();
    }

    public countSuperficieParcelas() {
        const ids = (this.model.ids_parcelas || '').split(';');
        this.model.parcelas_superficie = 0;

        (this.parcelas.filtered || []).forEach(parcela => {
            if (
                parcela && 
                parcela.value && 
                ids.includes((parcela.value as ParcelasModel).id) && 
                this.model.parcelas_superficie !== undefined
            ) {
                this.model.parcelas_superficie += 
                    parseFloat(((parcela.value as ParcelasModel).superficie_cultivada || '').replace(',', '.') || '0.00');
            }
        });
        const superficieFormateada = Utils.decimalFormat(this.model.parcelas_superficie, 2, '.', ',', 3);
        const idxParcelas = this.getItemPosition(this.formFields, 'ids_parcelas');
        const parcelas = this.formFields[idxParcelas];
        if (parcelas) {
            parcelas.label = this.parcelasName + ' (' + this.tipoSuperficieParcelas
            + ':\xa0' + superficieFormateada + '\xa0' + this.textoSuperficie + ')';
        }
    }

    public override getItemPosition(formFields: ItemInterface<object>[], field: string) {
        let position = -1;
        formFields.forEach((item, index) => {
            if (item.field === field) {
                position = index;
            }
        });
        return position;
    }

    private initFormData() {
        this.fincasApi.fincasGET.toPromise().then( (resp_fincas: FincasModel[]) => {
            
            if (this.appName === 'campanaragricola'){
                for (const key in resp_fincas) {
                    if (Object.prototype.hasOwnProperty.call(resp_fincas, key)) {
                        const element = resp_fincas[key];
                        if (element?.id_usuario === StorageManager.getUser().id){
                            this.model.id_finca = element.id;
                        }
                    }
                }
            }
            
            if (resp_fincas.length > 0 ) {
                if (resp_fincas.length === 1) {
                    this.model.id_finca = resp_fincas[0]?.id ?? '';
                } 
            }
            return;
        }).catch (e => {
            console.log('catch en getPosition: ' + e);
        }
        );
        const builds = [
            build(this.fincasApi.fincasGET, this.fincas, 'nombre', true),
            build(this.fincasApi.sectoresGET, this.sectores, 'nombre', true),
            build(this.fincasApi.parcelasGET, this.parcelas, 'nombre', true, true),
            build(
                environment.features.activateSIEX && !environment.features.hasPlantas ? 
                    this.siexApi.variedadesClienteGET : 
                    this.formApi.variedadesGET, 
                this.variedades, 
                'variedad', 
                true
            ),
            build(this.ventasApi.compradoresGET, this.compradores, 'razon_social', true),
            build(this.clientesApi.clientesGET, this.clientes, 'nombre'),            
        ];
        if (this.appName === 'pyf'){
            builds.push(build(this.ventasApi.compradoresGET, this.clientes_comercios, 'razon_social', true));
        }
        if (this.showClientesCooperativa) {
            builds.push(build(this.clientesApi.cooperativasGET, this.cooperativas, 'cooperativa'));
        }

        pool(builds, () => {
            setTimeout(() => {
                if (this.appName === 'campanaragricola') {

                    this.fincas.filtered = (this.fincas.filtered || [])
                        .filter((it) => it && it.value)
                        .map((it) => it.value)
                        .filter((it) =>
                            [it?.id, it?.nombre].includes(StorageManager.getUser().id_finca?.toString())
                        )
                        .map((it) => ({
                            label: it?.nombre ?? '',
                            value: it,
                        }))
                        .sort(Filtering.sort('label', 1));

                    this.fincas.filtered.unshift({
                        label: '...',
                        value: null,
                    });
                }
                this.countSuperficieParcelas();
                this.formRequest.update();
            }, 3000);
        });
    }
}
