import {list} from '../../../../../../common/classes/request-builder';
import {StorageManager} from '../../../../../../common/storage-manager.class';

import { FincasModel } from 'src/app/models/form-common/fincas.module';
import { SectorModel } from 'src/app/models/form-common/sector.model';
import { ParcelasModel } from 'src/app/models/form-common/parcelas.model';
import { MantenimientoModel } from 'src/app/models/trabajadores/mantenimiento.model';
import { MaquinariaModel } from 'src/app/models/trabajadores/maquinaria.model';
import { TratamientosModel } from 'src/app/models/tratamientos/tratamientos.model';
import { TareaFitoModel } from 'src/app/models/tareas/tarea-fito.model';
import { FilteredValuesInterface } from 'src/app/common/components/reactive-form-builder/filter.interface';
import { 
    FormInterface, 
    FormSectionInterface, 
    ItemInterface,
    ItemValuesInterface 
} from 'src/app/common/components/reactive-form-builder/item.interface';
import { InputType } from 'src/app/common/components/reactive-form-builder/input-type.enum';
import { FormType } from 'src/app/common/components/reactive-form-builder/form-type.enum';
import moment from 'moment';
import { Utils } from 'src/app/common/utils';
import { DropdownInterface } from 'src/app/common/components/form-builder/form-builder.component';
import { firstValueFrom } from 'rxjs';
import { RecuperarReferenciaRecetaService } from 'src/app/service/recuperar.service';
import { FitosanitariosEnvironmentDefinition } from '../../environment-variables';
import { FitosanitariosListsDefinition } from './list-variables';
import { FormRequestTypes } from 'src/app/common/components/reactive-form-builder/form-request';
import { Climatologia } from '../climatologia.interface';
import { WeatherService } from 'src/app/service/api/weather/weather';

export class FitosanitariosFormDefinition {

    public formType!: FormType;
    public env: FitosanitariosEnvironmentDefinition = new FitosanitariosEnvironmentDefinition();
    public lists: FitosanitariosListsDefinition = new FitosanitariosListsDefinition();

    public cliente = StorageManager.getClient();
    public usuario = StorageManager.getUser();
    public activateRequireHourForRolUser = this.env.appName === 'ava' && this.usuario.rol !== 'admin';

    public recuperarService!: RecuperarReferenciaRecetaService;
    public weatherService!: WeatherService;
    public model: TareaFitoModel = {
        id_cliente: this.cliente?.id,
        id_usuario: StorageManager.getUser().id
    };
    public ids_productos: string[] | undefined;
    public referenciaReceta: string | null = null;
    public tag = '';
    
    public formFieldsHidden!: FormSectionInterface<object>;
    public formFieldsTiempo!: FormSectionInterface<object>;
    public formFieldsKM!: FormSectionInterface<object>;
    public formFieldsLugar!: FormSectionInterface<object>;
    public formFieldsTratamiento!: FormSectionInterface<object>;
    public formFieldsTractor!: FormSectionInterface<object>;
    public formFieldsInnovia!: FormSectionInterface<object>;
    public formFieldsFinal!: FormSectionInterface<object>;
    public formFieldsClima!: FormSectionInterface<object>;
    public formFieldsMezclasSobrantes!: FormSectionInterface<object>;
    public formFieldsOrdenTrabajo!: FormSectionInterface<object>;
    public formFieldsObs!: FormSectionInterface<object>;


    public formFields!: FormInterface<object>;

    public now = new Date();
    public day = this.now.getDate().toString().padStart(2, '0');
    public month = (this.now.getMonth() + 1).toString().padStart(2, '0');
    public year = this.now.getFullYear().toString();
    public hours = this.now.getHours().toString().padStart(2, '0');
    public minutes = this.now.getMinutes().toString().padStart(2, '0');


    constructor(type: FormRequestTypes, model: Record<string,string>, table: (string[] | string[][])[] | null) {
        this.formType = +type;
        this.ids_productos = model['ids_productos']?.split(';');
        this.referenciaReceta = this.formType === 2 ? null : model['referencia_receta'] ?? '';
        this.formFields = {
            name: 'fitosanitarios',
            type: this.formType,
            sections: this.getFormSections(),
            table: {data: table?.[0], style: table?.[1]},
            signed: this.env.appName === 'donjardin' || this.env.appName === 'coliberica' || this.env.appName === 'demogardengest'
        };
    }

    public getDefaultPresionValues() {
        switch (this.env.appName) {
        case 'laplana':
            return this.lists.presionLaplana;
        default:
            return this.lists.presionAll;
        }
    }

    public checkIfProductIsValid(fecha_caducidad: string) {
        const diff = moment(new Date()).diff(Utils.toDate(fecha_caducidad), 'months');
    
        if (diff > 12) {
            return '* ';
        } else if (diff > 6) {
            return '* ';
        } else if (diff >= 0) {
            return '* ';
        }
    
        return '';
    }

    public getTiempoFields() {
        const tiempoFields = [
            {
                field: 'fecha_inicio',
                size: 2,
                name: (this.env.appName === 'armentia') ? 'Fecha inicio' : 'Fecha Receta',
                label: (this.env.appName === 'armentia') ? 
                    'Fecha inicio' : 
                    'Fecha Receta',
                inputType: {type: InputType.CALENDAR},
                visible: !this.env.showTareasMto,
                order: 1
            },
        ];
        return tiempoFields.sort((a, b) => (a.order ?? 0) - (b.order ?? 0));
    }

    public getKMFields() {
        const kmFields = [
            {
                field: 'punto_km_inicio',
                size: 6,
                name: 'Punto Km inicial',
                label: 'Punto Km inicial',
                inputType: {type: InputType.EDIT_TEXT},
                visible: true,
                order: 1
            },
            {
                field: 'punto_km',
                size: 6,
                name: 'Punto Km final',
                label: 'Punto Km final',
                inputType: {type: InputType.EDIT_TEXT},
                visible: true,
                order: 2
            }
        ];
        return kmFields.sort((a, b) => (a.order ?? 0) - (b.order ?? 0));
    }

    public getLugarFields() {
        const lugarFields = [
            { 
                field: 'id_cliente',
                size: 4,
                name: this.env.clienteName,
                label: this.env.clienteName,
                inputType: {type: InputType.DROPDOWN_SEARCH},
                values: this.lists.clientes,
                valuePrimaryKey: 'id',
                required: true,
                visible: this.env.hasClients && this.env.userCanSeeClientes && this.formType === FormType.DUPLICATION,
                order: 1
            },
            {
                field: this.formType < 3 ? 'id_finca' : 'finca',
                size: 4,
                name: this.env.fincaName,
                label: this.env.fincaName,
                inputType: {type: InputType.DROPDOWN_SEARCH},
                values: this.lists.fincas,
                valuePrimaryKey: 'id',
                vinculatedFields: ['id_cliente'],
                required: true,
                filter: (it: FilteredValuesInterface<FincasModel>) => {
                    const storedClient = StorageManager.getClient();
                    return storedClient ? it.value?.id_cliente === storedClient.id : true;
                },
                visible: this.env.hasFincas && this.env.showTareasFincas,
                order: 2
            },
            {
                field: this.formType < 3 ? 'id_sector' : 'sectores_nombres',
                size: 4,
                name: this.env.sectorName,
                label: this.env.sectorName,
                inputType: {type: this.env.showTareasFitoSectorMultiple ? InputType.DROPDOWN_MULTISELECT_SEARCH : InputType.DROPDOWN},
                multiSelect: this.env.showTareasFitoSectorMultiple,
                values: this.lists.sectores,
                valuePrimaryKey: 'id',
                vinculatedFields: ['id_finca'],
                filter: (it: FilteredValuesInterface<SectorModel>) =>  {
                    if (!it.value?.nombre) {
                        return;
                    }

                    return it.value.id_finca === this.lists.fincas?.selected?.id;
                },
                visible: this.env.showTareasSector,
                order: 3
            },
            {
                field: this.formType < 3 ? 'ids_parcelas' : 'parcelas_nombres',
                size: 4,
                name: this.env.parcelasName,
                label: this.env.parcelasName,
                sum: {field: 'superficie_cultivada', uds: this.env.textoSuperficie, name: this.env.tipoSuperficieParcelas},
                inputType: {type: InputType.DROPDOWN_MULTISELECT_SEARCH},
                values: this.lists.parcelas,
                valuePrimaryKey: 'id',
                multiSelect: true,
                canSelectAll: true,
                visible: this.env.showTareasParcela,
                vinculatedFields: ['id_finca', 'id_sector'],
                filter: (it: FilteredValuesInterface<ParcelasModel>) => {
                    if (it.value?.activo !== '1') {
                        return false;
                    }

                    const sectoresSeleccionados = this.lists.sectores.selected;
                    const fincaSeleccionada = this.lists.fincas.selected;

                    if (sectoresSeleccionados && +(sectoresSeleccionados.length) > 0) {
                        if (this.env.showTareasFitoSectorMultiple && Array.isArray(sectoresSeleccionados)) {
                            return sectoresSeleccionados.some((sector: SectorModel) => sector?.id === it.value?.id_sector);
                        } else {
                            return it.value.id_sector === (sectoresSeleccionados as SectorModel).id;
                        }
                    }

                    return it.value.id_finca === fincaSeleccionada?.id;
                },
                required: true,
                order: 4
            }
        ];

        return lugarFields.sort((a, b) => (a.order ?? 0) - (b.order ?? 0));
    }

    public getTratamientoFields() {
        const tratamientoFields = [
            {
                field: this.formType < 3 ? 'ids_trabajadores' : 'trabajadores_nombres',
                size: 6,
                name: 'Profesionales',
                label: 'Profesionales',
                inputType: {type: InputType.DROPDOWN_MULTISELECT_SEARCH},
                values: this.lists.trabajadores,
                valuePrimaryKey: 'id',
                multiSelect: true,
                vinculatedFields: ['id_cliente'],
                updateValue: (it: ItemInterface<DropdownInterface<MantenimientoModel>>) => {
                    const filteredValues = 
                    (it.values as ItemValuesInterface<DropdownInterface<MantenimientoModel>>)?.filtered ?? [];

                    if (this.env.showTareasMto){
                        const userInfo = StorageManager.getUser();
                        if (userInfo.rol === 'user' && this.formType === FormType.CREATION){
                        
                            return (filteredValues as DropdownInterface<MantenimientoModel>[])
                                .find(trabajador => trabajador.value?.id === userInfo.id)?.value;
                        }
                    }

                    return (filteredValues as DropdownInterface<MantenimientoModel>[])
                        .find(trabajador => trabajador.value?.id_maquina === this.model.id_maquinaria)?.value;
                },
                filter: (it: FilteredValuesInterface<MantenimientoModel>) => {
                    if (it.value?.activo !== '1') {
                        return false;
                    }
                    
                    this.showExpirationIdentifier(it);
                                        
                    
                    return true;
                    
                },
                visible: true,
                order: 1
            },
            {
                field: this.formType < 3 ? 'ids_asignados' : 'nombres_asignados',
                size: 6,
                name: 'Asignador',
                label: 'Asignador',
                inputType: {type: InputType.MULTISELECT},
                multiSelect: true,
                canSelectAll: true,
                valuePrimaryKey: 'id',
                values: this.lists.usuarios,
                visible: (this.env.showAssignUserTasksFitosanitario || this.env.showAssignUserInAllTasks) && 
                    this.usuario.rol === 'admin',
                required: true,
                order: 2
            },
            {
                field: 'momento_aplicacion',
                size: 6,
                name: 'Momento de aplicación',
                label: 'Momento de aplicación',
                inputType: {type: InputType.DROPDOWN},
                values: this.lists.momentosAplicacion,
                visible: this.env.showFitoMomentoAplicacion,
                order: 3
            },
            {
                field: 'coste_tratamiento',
                size: 6,
                name: 'Coste (€)',
                label: 'Coste (€)',
                inputType: {type: InputType.EDIT_NUMERIC},
                visible: this.env.showFitoCosteTratamiento,
                order: 4
            },
            {
                field: 'tipo_tratamiento',
                size: 6,
                name: 'Tipo de tratamiento',
                label: 'Tipo de tratamiento',
                inputType: {type: InputType.DROPDOWN},
                values: this.lists.tiposTratamiento,
                visible: this.env.showFitoTipoTratamiento,
                order: 5
            },
            {
                field: this.formType < 3 ? 'id_tratamiento' : 'tratamiento_nombre',
                size: 6,
                name: 'Tratamientos',
                label: 'Tratamientos',
                inputType: {type: InputType.DROPDOWN_SEARCH},
                values: this.lists.tratamientos,
                valuePrimaryKey: 'id',
                vinculatedFields: ['id_cliente'],
                filter: (it: FilteredValuesInterface<TratamientosModel>) => {
                    if (!it?.value || (!it?.value?.nombre || it?.value.nombre === 'null' || it.value.nombre.trim().length === 0)) {
                        return false;
                    }

                    this.showExpirationIdentifier(it);

                    const allTratamientosAllowed =
                        (this.env.mostrarTodosTratamientos ||
                        (['abonosfolques', 'agricar', 'lluchychulbi'].includes(this.env.appName)) ||
                        (!this.env.hasClients || this.formType === FormType.DUPLICATION || !this.env.userCanSeeClientes));

                    const idCliente =
                        this.lists.fincas.selected?.id_cliente ||
                        this.lists.clientes.selected?.id ||
                        StorageManager.getClient()?.id;

                    return allTratamientosAllowed ? true : it.value.id_cliente === idCliente;

                },
                visible: true,
                order: 6
            },
            {
                field: 'ph_caldo',
                size: 4,
                name: 'pH caldo',
                label: 'pH caldo',
                inputType: {type: InputType.DROPDOWN},
                values: this.lists.ph_caldo,
                visible: this.env.showPhCaldo,
                order: 7
            },
            {
                field: 'litros',
                size: 4,
                name: this.env.appName === 'momparler' ? 'Litros/m²' : this.env.appName === 'agrosalvi' ? 'Volumen (gr o litros agua)' :
                    this.env.appName === 'onda' ? 'Litros' : this.env.appName === 'hernandorena' ? 'Caldo (litros)' :
                        this.env.appName !== 'innovia' ? 
                            'Litros/' + this.env.textoSuperficie + (this.env.appName === 'vginer' ? ' (recomendación)' : '') :
                            'Litros Caldo',
                label: this.env.appName === 'momparler' ? 'Litros/m²' : this.env.appName === 'agrosalvi' ? 'Volumen (gr o litros agua)' :
                    this.env.appName === 'onda' ? 'Litros' : this.env.appName === 'hernandorena' ? 'Caldo (litros)' :
                        this.env.appName !== 'innovia' ? 
                            'Litros/' + this.env.textoSuperficie + (this.env.appName === 'vginer' ? ' (recomendación)' : '') :
                            'Litros Caldo',
                inputType: {type: this.env.showTareasMto ? InputType.EDIT_NUMERIC : InputType.EDIT_TEXT},
                visible: true,
                order: 8
            },
            {
                field: 'mochila',
                size: 4,
                name: 'Es mochila',
                label: 'Es mochila',
                inputType: {type: InputType.SWITCH},
                visible: this.env.showTareasFitoMochila,
                order: 9
            },
            {
                field: 'sup_tratada',
                size: 4,
                name: 'Superficie tratada',
                label: 'Superficie tratada',
                inputType: {type: InputType.DROPDOWN},
                values: list(['...', null], 'T', 'P', 'F'),
                visible: this.env.appName === 'pyf',
                order: 10
            },
            {
                field: 'orden_asesor',
                size: 6,
                name: 'Orden asesor',
                label: 'Orden asesor',
                inputType: {type: InputType.SWITCH},
                rememberOnDuplicate: false,
                updateValue: (it: ItemInterface<object>) => {
                    if (!this.referenciaReceta) {                        
                        if (this.model.fecha_inicio && this.model.orden_asesor) {
                            const fecha = this.parseFecha(this.model.fecha_inicio);
                        
                            firstValueFrom(this.recuperarService.getRecuperarReferencia(fecha.getFullYear()))
                                .then((resp) => {
                                    const numero = this.padZero(3, resp?.data[0]?.numero ?? '');
                                    const referenciaReceta = `${fecha.getFullYear()}-${numero}`;
                                    it.label = `Orden asesor - ${referenciaReceta}`;
                                    it.isEditable = false;
                                    this.referenciaReceta = referenciaReceta;
                                    return;
                                })
                                .catch((e) => console.error('Error en getRecuperarReferencia:', e));
                        } else {
                            it.label = 'Orden asesor';
                            this.model.referencia_receta = '';
                        }                    
                    } else {
                        it.isEditable = false;
                        it.label = `Orden asesor - ${this.referenciaReceta}`;
                    }
                },
                visible: this.env.appName === 'pyf',
                order: 11
            },
            {
                field: 'aplicacion',
                size: 6,
                name: 'Modo de aplicación',
                label: 'Modo de aplicación',
                inputType: {type: InputType.DROPDOWN},
                values: list(['...', null], 'Arcén', 'Medianera'),
                visible: this.env.showTareasFitoAplicacion,
                order: 12
            },
            {
                field: 'procura',
                size: 6,
                name: 'Procura',
                label: 'Procura',
                inputType: { type: InputType.DROPDOWN_SEARCH },
                values: list(['...', null], 'Procura', 'Media procura', 'Libre'),
                visible: this.env.showTareasProcura,
                order: 13
            },
            {
                field: 'tipo_plaga', 
                size: 6,
                name: 'Tipo de plaga',
                label: 'Tipo de plaga', 
                inputType: {type: InputType.DROPDOWN}, 
                values: this.lists.tipo_plaga,
                visible: this.env.showTipoPlaga,
                order: 14
            },
            {
                field: 'litros_totales',
                size: 6,
                name: 'Litros Totales',
                label: 'Litros Totales',
                inputType: { type: InputType.EDIT_TEXT },
                visible: this.env.appName === 'gragon',
                order: 15
            },
            {
                field: 'motivo', 
                size: 6,
                name: 'Motivo',
                label: 'Motivo', 
                inputType: {type: InputType.DROPDOWN}, 
                values: this.lists.motivo,
                visible: this.env.showMotivoFito && this.env.appName !== 'gragon',
                order: 16,
            },
            {
                field: 'codigo_justificacion',
                size: this.env.appName === 'donjardin' ? 4 : 6,
                name: 'Justificación de la Actuación',
                label: 'Justificación de la Actuación',
                inputType: {type: InputType.DROPDOWN_SEARCH},
                values: this.lists.justificaciones,
                valuePrimaryKey: 'codigo',
                visible: true,
                order: 17
            },
            {
                field: 'desplazamiento',
                size: 4,
                name: 'Desplazamiento',
                label: 'Desplazamiento',
                inputType: {type: InputType.DROPDOWN},
                values: this.lists.desplazamiento,
                visible: this.env.showDesplazamientoObra,
                order: 18
            },
            {
                field: 'mano_obra',
                size: 4,
                name: 'Mano Obra',
                label: 'Mano Obra',
                inputType: {type: InputType.DROPDOWN},
                values: this.lists.mano_obra,
                visible: this.env.showDesplazamientoObra,
                order: 19
            },
            {
                field: 'observaciones',
                size: this.env.appName === 'donjardin' ? 6 : 4,
                name: 'Recomendación',
                label: 'Recomendación',
                inputType: {type: InputType.EDIT_TEXTAREA},
                visible: this.env.showTareasFitoObservaciones,
                audio: true,
                order: 20
            },
            {
                field: 'momento_aplicacion',
                size: 4,
                name: 'Momento de aplicación',
                label: 'Momento de aplicación',
                inputType: { type: InputType.EDIT_TEXT },
                visible: this.env.showMomentoAplicacion,
                order: 21
            },
            {
                field: 'limpieza_epis',
                size: 4,
                name: 'Limpieza de EPIS',
                label: 'Limpieza de EPIs',
                inputType: {type: InputType.SWITCH},
                visible: this.env.appName === 'vginer',
                order: 22
            },
            {
                field: 'dia_limpieza',
                size: 4,
                name: 'Día de limpieza',
                label: 'Día de limpieza',
                inputType: {type: InputType.CALENDAR},
                visible: this.env.appName === 'vginer',
                order: 23
            },
            {
                field: 'it',
                size: 4,
                name: 'Instrucción técnica',
                label: 'Instrucción técnica',
                inputType: {type: InputType.EDIT_TEXT},
                visible: this.env.showTareasFitoIT,
                order: 24
            },
            {
                field: 'lote',
                size: 4,
                name: 'Lote',
                label: 'Lote',
                inputType: {type: InputType.EDIT_TEXT},
                visible: this.env.showTareasFitoLote,
                order: 25
            },
            {
                field: 'tecnica',
                size: 4,
                name: 'Técnica',
                label: 'Técnica',
                inputType: {type: InputType.DROPDOWN},
                values: this.lists.tecnica,
                visible: this.env.appName === 'pyf',
                order: 26
            },
            {
                field: 'notas_cliente',
                size: this.env.appName === 'donjardin' ? 6 : 4,
                name: 'Notas cliente',
                label: 'Notas cliente',
                inputType: {type: InputType.EDIT_TEXTAREA},
                defaultValue: this.formType === FormType.CREATION ? StorageManager.getClient()?.notas : null,
                retain: true,
                visible: this.env.showClientesNotas,
                isEditable: false,
                order: 27
            },
            {
                field: 'estado_tarea',
                size: 4,
                name: 'Estado de la Tarea',
                label: 'Estado de la Tarea',
                inputType: {type: InputType.DROPDOWN},
                values: this.lists.estadoTarea,
                visible: this.env.appName === 'onda' || this.env.appName === 'lacooperativa',
                order: 28
            },
            {
                field: 'empresa_pagadora',
                size: 4,
                name: 'Empresa Pagadora',
                label: 'Empresa Pagadora',
                inputType: {type: InputType.EDIT_TEXT},
                visible: this.env.appName === 'onda' || this.env.appName === 'lacooperativa',
                order: 29
            },
        ];
        return tratamientoFields.sort((a, b) => (a.order ?? 0) - (b.order ?? 0));
    }

    public getTractorFields() {
        const tractorFields = [
            {
                field: this.formType < 3 ? 'id_maquinaria' : 'maquinaria',
                size: 4,
                name: 'Maquinaria',
                label: 'Maquinaria',
                inputType: {
                    type:  
                        (this.env.appName === 'bravosol' || this.env.appName === 'agrosalvi') ? 
                            InputType.MULTISELECT : InputType.DROPDOWN_SEARCH
                },
                multiSelect: this.env.appName === 'bravosol' || this.env.appName === 'agrosalvi',
                values: this.lists.maquinarias,
                valuePrimaryKey: 'id',
                vinculatedFields: this.env.appName === 'pyf' ? ['ids_cliente'] : undefined,
                visible: this.env.showTareasFitoMaquinarias,
                updateValue: () => this.lists.trabajadores.selected?.id_maquina ?? '',
                filter: (it: FilteredValuesInterface<MaquinariaModel>) => {
                    if (it.value?.activo !== '1') {
                        return false;
                    }

                    this.showExpirationIdentifier(it);
                    
                    if (this.env.appName === 'pyf' && StorageManager.getClient()?.id) {
                        return it.value.id_cliente === StorageManager.getClient().id;
                    }
                    
                    return true;
                },
                order: 1
            },
            {
                field: 'velocidad',
                size: 4,
                name: 'Velocidad\xa0(Km/h)',
                label: 'Velocidad\xa0(Km/h)',
                inputType: {type: InputType.EDIT_NUMERIC},
                visible: this.env.showTractorVelocidad,
                order: 2
            },
            {
                field: 'marcha',
                size: 4,
                name: 'Marcha',
                label: 'Marcha',
                inputType: {type: InputType.EDIT_TEXT},
                visible: this.env.showTractorMarcha,
                order: 3
            },
            {
                field: 'rpm',
                size: 4,
                name: 'RPM',
                label: 'RPM',
                inputType: {type: InputType.DROPDOWN_SEARCH},
                values: this.lists.rpmValues,
                visible: this.env.showTractorRPM,
                order: 4
            },
            {
                field: 'presion',
                size: 4,
                name: 'Presión\xa0(atm-bar)',
                label: 'Presión\xa0(atm-bar)',
                inputType: {type: (['simat', 'laplana'].includes(this.env.appName)) ? InputType.DROPDOWN : InputType.EDIT_NUMERIC},
                values: ['simat', 'laplana'].includes(this.env.appName) ? this.getDefaultPresionValues() : {},
                swap: (it: ItemInterface<object>) => {
                    if (this.env.appName === 'simat') {
                        switch (this.lists.maquinarias.selected?.tipo) {
                        case 'Herbicida':
                            it.values = this.lists.presionHerbicida;
                            break;
                        case 'Turboatomizador':
                            it.values = this.lists.presionTurbo;
                            break;
                        case 'Disparador':
                            it.values = this.lists.presionDisparador;
                            break;
                        default:
                            it.values = this.lists.presionAll;
                            break;
                        }
                    }
                },
                visible: this.env.showTractorPresion,
                order: 5
            },
            {
                field: 'boquillas',
                size: 4,
                name: 'Boquillas',
                label: 'Boquillas',
                inputType: {type: InputType.EDIT_TEXT},
                visible: this.env.showTractorBoquillas,
                order: 6
            },
            {
                field: 'km_recorridos',
                size: 4,
                name: 'Km recorridos',
                label: 'Km recorridos',
                inputType: {type: InputType.EDIT_TEXT},
                visible: this.env.showTractorKmRecorridos,
                order: 7
            }
        ];
        return tractorFields.sort((a, b) => (a.order ?? 0) - (b.order ?? 0));
    }

    public getInnoviaFields() {
        const innoviaFields = [
            {
                field: 'litros1',
                size: 4,
                name: 'Litros 1',
                label: 'Litros ',
                inputType: {type: InputType.EDIT_NUMERIC},
                turnVisible: (it: ItemInterface<object>) => this.changeLabel(this.ids_productos?.[0], it),
                visible: true,
                order: 1
            },
            {
                field: 'litros2',
                size: 4,
                name: 'Litros 2',
                label: 'Litros ',
                inputType: {type: InputType.EDIT_NUMERIC},
                turnVisible: (it: ItemInterface<object>) => this.changeLabel(this.ids_productos?.[1], it),
                visible: true,
                order: 2
            },
            {
                field: 'litros3',
                size: 4,
                label: 'Litros ',
                inputType: {type: InputType.EDIT_NUMERIC},
                turnVisible: (it: ItemInterface<object>) => this.changeLabel(this.ids_productos?.[2], it),
                visible: true,
                order: 3
            },    
        ];
        return innoviaFields.sort((a, b) => (a.order ?? 0) - (b.order ?? 0));
    }

    public getFinalFields() {
        const finalFields = [
            {
                field: 'hora_inicio',
                size: 4,
                name: 'Hora Inicio',
                label: 'Hora Inicio',
                inputType: {type: InputType.TIME},
                rememberOnDuplicate: !this.env.showTareasMto,
                visible: this.env.showTareasMto || this.env.showTareasFitoHoras || this.env.timeStartStopTareas,
                required: this.activateRequireHourForRolUser,
                order: 1
            },
            {
                field: 'hora_fin',
                size: 4,
                name: 'Hora Fin',
                label: 'Hora Fin',
                inputType: {type: InputType.TIME},
                rememberOnDuplicate: !this.env.showTareasMto,
                defaultValue: 
                    (this.env.horaFechaFinActualPorDefecto || this.env.showTareasMto && this.usuario.rol === 'user') ? 
                        this.formType !== FormType.EDITION ? `${this.hours}:${this.minutes}` : 
                            undefined : undefined,
                visible: this.env.showTareasMto || this.env.showTareasFitoHoras || this.env.timeStartStopTareas,
                required: this.activateRequireHourForRolUser,
                order: 2
            },
            {
                field: 'horas_totales',
                size: 4,
                name: 'Horas',
                label: 'Horas',
                inputType: {type: InputType.ONLY_TEXT},
                rememberOnDuplicate: !this.env.showTareasMto,
                updateValue: () => this.formatoHorasMinutos(this.model.hora_inicio || '00:00', this.model.hora_fin?.toString() || '00:00'),
                visible: this.env.showTareasMto || this.env.tareasHorasTotales || this.env.showTareasFitoHoras,
                order: 3
            },
            {
                field: 'fecha_fin',
                size: 4,
                name: (this.env.appName === 'armentia') ? 'Fecha fin' : 'Fecha Aplicación',
                label: (this.env.appName === 'armentia') ? 'Fecha fin' : 'Fecha Aplicación',
                inputType: {type: InputType.CALENDAR},
                rememberOnDuplicate: false,
                defaultValue: (this.env.horaFechaFinActualPorDefecto) ? 
                    this.formType !== FormType.EDITION  && 
                    (this.env.appName !== 'sirga' && this.env.appName !== 'yagoaznar') ? `${this.day}/${this.month}/${this.year}` : 
                        undefined : undefined,
                required: this.env.appName === 'agrosalvi' && this.usuario.rol === 'user',
                visible: true,
                order: 4
            },
            {
                field: 'fin_fecha',
                size: 4,
                name: 'Fecha fin',
                label: 'Fecha fin',
                inputType: {type: InputType.CALENDAR},
                visible: this.env.appName === 'fotoagricultura',
                order: 5
            },
            {
                field: 'fecha_recoleccion',
                size: 4,
                name: 'Fecha Recolección',
                label: 'Fecha Recolección',
                inputType: {type: InputType.CALENDAR},
                visible: this.env.appName === 'vginer',
                order: 6
            },
            {
                field: 'eficacia',
                size: 4,
                name: 'Eficacia del Tratamiento',
                label: 'Eficacia del Tratamiento',
                rememberOnDuplicate: false,
                inputType: {type: InputType.DROPDOWN_SEARCH},
                values: this.lists.eficacia,
                visible: true,
                required: this.env.appName === 'agrosalvi' && this.usuario.rol === 'user',
                order: 7
            },
            {
                field: 'aplicado',
                size: 4,
                name: 'Aplicado',
                label: 'Aplicado',
                rememberOnDuplicate: false,
                inputType: {type: InputType.SWITCH},
                defaultValue: (this.env.showTareasMto && this.usuario.rol === 'user') ? '1' : '0',
                visible: (this.env.appName === 'agrosalvi' && this.usuario.rol !== 'user') || this.env.showAplicadoSwitch,
                order: 8
            },
            {
                field: 'horas_totales',
                size: 4,
                name: 'Horas',
                label: 'Horas',
                inputType: {type: InputType.EDIT_NUMERIC},
                visible: this.env.appName === 'fotoagricultura',
                order: 9
            },
            {
                field: 'validado',
                size: 4,
                name: 'Validado',
                label: 'Validado',
                inputType: {type: InputType.SWITCH},
                visible: this.env.showFitoValidado && (this.usuario.rol === 'admin'),
                order: 10
            },
            {
                field: 'soleado',
                size: 4,
                name: 'Soleado',
                label: 'Soleado',
                inputType: {type: InputType.DROPDOWN},
                values: list(['...', null], 'Cubierto', 'Semicubierto', 'Despejado'),
                visible: this.env.showTareasFitoSoleado,
                order: 11
            },
            {
                field: 'cubas_totales',
                size: 4,
                name: 'Cubas totales',
                label: 'Cubas totales',
                inputType: {type: InputType.EDIT_TEXT},
                visible: this.env.appName === 'vginer',
                order: 12
            },
            {
                field: 'litros_aplicados',
                size: 4,
                name: this.env.appName === 'ava' ? 'Vol. Caldo Gastado' : 'Litros aplicados',
                label: this.env.appName === 'ava' ? 'Vol. Caldo Gastado' : 'Litros aplicados',
                inputType: {type: InputType.EDIT_TEXT},
                visible: this.env.showTareasFitoLitrosAplicados,
                order: 13
            },
            {
                field: 'litros_aplicados_ha',
                size: 4,
                name: 'Litros aplicados/Ha',
                label: 'Litros aplicados/Ha',
                inputType: {type: InputType.ONLY_TEXT},
                updateValue: () => {
                    const superficie = parseFloat((this.model.sup_cultivada || '0').toString().replace(',', '.')) || 0;
                    const litrosAplicados = this.model.litros_aplicados || 0;
                    
                    let litrosAplicadosHa = +Utils.decimalFormat(+litrosAplicados / superficie);

                    if (!litrosAplicadosHa || !isFinite(litrosAplicadosHa)) {
                        litrosAplicadosHa = 0;
                    }

                    return litrosAplicadosHa.toString();
                },
                visible: this.env.showTareasFitoLitrosAplicados && !this.env.showCostes,
                order: 14
            },
            {
                field: 'facturado',
                size: 4,
                name: 'Facturado',
                label: 'Facturado',
                inputType: {type: InputType.SWITCH},
                visible: this.env.appName === 'agrosalvi',
                order: 15
            },
            {
                field: 'factura_albaran',
                size: 4,
                name: 'Factura/Albarán',
                label: 'Factura/Albarán',
                inputType: {type: InputType.EDIT_TEXT},
                visible: this.env.appName === 'agrosalvi',
                order: 16
            }
        ];
        return finalFields.sort((a, b) => (a.order ?? 0) - (b.order ?? 0));
    }

    public getClimaFields() {
        const climaFields = [
            {
                field: 'temperatura',
                size: 4,
                name: 'Temperatura ℃',
                label: 'Temperatura ℃',
                inputType: {type: InputType.EDIT_TEXT},
                visible: true,
                order: 1
            },
            {
                field: 'hr',
                size: 4,
                name: this.env.appName === 'torrepacheco' ? 'Humedad %' : 'Humedad R.',
                label: this.env.appName === 'torrepacheco' ? 'Humedad %' : 'Humedad R.',
                inputType: {type: InputType.EDIT_TEXT},
                visible: true,
                order: 2
            },
            {
                field: 'viento',
                size: 4,
                name: 'Viento Km/h',
                label: 'Viento Km/h',
                inputType: {type: InputType.EDIT_TEXT},
                visible: true,
                order: 3
            },
            {
                field: 'precip',
                size: 4,
                name: 'Precipit. L/m²',
                label: 'Precipit. L/m²',
                inputType: {type: InputType.EDIT_TEXT},
                visible: true,
                order: 4
            },
            {
                field: 'clima',
                size: 4,
                name: 'Clima',
                label: 'Clima',
                inputType: {type: InputType.DROPDOWN},
                values: list(['...', null], 'Cubierto', 'Semidespejado', 'Despejado'),
                visible: this.env.appName === 'vginer',
                order: 5
            },
            {
                field: 'climatologia',
                size: 4,
                label: '',
                inputType: {type: InputType.SELECT_BUTTON},
                values: list( ['Obtener clima', null] ),
                onClick: (it: ItemInterface<any>) => this.checkWeatherVariables(it),
                visible: true,
                retain: true,
                order: 6
            },
        ];
        return climaFields.sort((a, b) => (a.order ?? 0) - (b.order ?? 0));
    }

    public getMezclasSobrantesFields() {
        const mezcalsSobrantesFields = [
            {
                field: 'cubeto_evaporacion',
                size: 4,
                name: 'Cubeto evaporación',
                label: 'Cubeto evaporación',
                inputType: {type: InputType.SWITCH},
                visible: true,
                order: 1
            },
            {
                field: 'punto_carga',
                size: 4,
                name: 'Punto carga',
                label: 'Punto carga',
                inputType: {type: InputType.SWITCH},
                visible: true,
                order: 2
            },
            {
                field: 'litros_sobrante',
                size: 4,
                name: 'Litros caldo sobrante',
                label: 'Litros caldo sobrante',
                inputType: {type: InputType.EDIT_NUMERIC},
                visible: true,
                order: 3
            },
            {
                field: 'dia_tratamiento',
                size: 4,
                name: 'Día tratamiento',
                label: 'Día tratamiento',
                inputType: {type: InputType.CALENDAR},
                visible: true,
                order: 4
            },
        ];
        return mezcalsSobrantesFields.sort((a, b) => (a.order ?? 0) - (b.order ?? 0));
    }

    public getOrdenTrabajoFields() {
        const ordenTrabajoFields = [
            {
                field: 'orden_trabajo',
                size: 12,
                name: 'Orden de trabajo',
                label: 'Orden de trabajo',
                inputType: { type: InputType.EDIT_TEXTAREA },
                visible: true,
                isEditable: this.usuario.rol === 'admin',
                audio: true,
                camera: true,
                camera_field: 'imagen',
                order: 1
            },
        ];
        return ordenTrabajoFields.sort((a, b) => (a.order ?? 0) - (b.order ?? 0));
    }

    public getObsFields() {
        const obsFields = [
            {
                field: 'observaciones',
                size: 12,
                name: 'Observaciones',
                label: 'Observaciones',
                inputType: { type: InputType.EDIT_TEXTAREA },
                visible: true,
                audio: true,
                camera: true,
                camera_field: 'image_after',
                order: 1
            }
        ];
        return obsFields.sort((a, b) => (a.order ?? 0) - (b.order ?? 0));
    }
    
    private formatoHorasMinutos(horaEntrada: string, horaSalida: string): string {

        if (!this.isValidTime(horaEntrada) || !this.isValidTime(horaSalida)) {
            console.error('El formato de hora no es válido. Asegúrese de que el formato sea HH:mm');
            return '00:00';
        }
    
        const duracion = moment.duration(
            moment(horaSalida, 'HH:mm').diff(moment(horaEntrada, 'HH:mm'))
        );
    
        return `${this.padZero(2, Math.floor(duracion.asHours()).toString())}:${this.padZero(2, duracion.minutes().toString())}`;
    }
    
    private isValidTime(hora: string): boolean {
        return moment(hora, 'HH:mm', true).isValid();
    }

    private showExpirationIdentifier(it: FilteredValuesInterface<MaquinariaModel | MantenimientoModel | TratamientosModel>) {
        if (it.label.includes('*')) {
            return;
        }
    
        // Si no está activo, marcar como ** (doble asterisco)
        if (!it.value?.activo) {
            it.label = `** ${it.label}`;
            return;
        }
    
        // Verificar fecha de caducidad general (fecha_caducidad o fecha_ultima_inspeccion)
        const fechaCaducidad = this.obtenerFechaCaducidad(it.value);
        if (!fechaCaducidad) {
            return;
        }
    
        const hoy = moment();
        const tresMeses = moment().add(3, 'months');
        const tiempoAntes = this.comprobarTiempoAntes(fechaCaducidad);
    
        // Evaluar estado de la fecha de caducidad
        if (fechaCaducidad.isBefore(hoy)) {
            it.label = `** ${it.label}`; // Expirado
        } else if (fechaCaducidad.isBefore(tresMeses) || tiempoAntes) {
            it.label = `* ${it.label}`; // Próximo a caducar
        }
    }
    
    private obtenerFechaCaducidad(value: MaquinariaModel): moment.Moment | null {
        if (value?.fecha_caducidad) {
            return moment(Utils.toDate(value.fecha_caducidad));
        } 
    
        if (value?.fecha_ultima_inspeccion) {
            const auxFechaCad = (value.fecha_ultima_inspeccion as string).split('/');
            const edadMaquinaria = moment().year() - moment(value.fecha_adquisicion, 'DD/MM/YYYY').year();
    
            const incremento = edadMaquinaria > 10 || isNaN(edadMaquinaria) 
                ? parseInt(auxFechaCad[2] ?? '', 10) >= 2020 ? 3 : 5 
                : 10;
    
            auxFechaCad[2] = (parseInt(auxFechaCad[2] ?? '', 10) + incremento).toString();
    
            return moment(`${auxFechaCad[0]}/${auxFechaCad[1]}/${auxFechaCad[2]}`, 'DD/MM/YYYY');
        }
    
        return null;
    }
    
    private comprobarTiempoAntes(fechaCaducidad: moment.Moment): boolean {
        const margen = this.env.appName === 'prodalbar' ? '1 years' : '3 months';
        return fechaCaducidad.isBefore(moment().add(moment.duration(margen)));
    }
    

    private parseFecha(fechaInicio: string | Date): Date {
        if (fechaInicio.toString().length === 10) {
            const [dia, mes, anio] = fechaInicio.toString().split('/').map(Number);
            return new Date(anio ?? 0, (mes ?? 0) - 1, dia);
        }
        return new Date(fechaInicio);
    }
    
    private padZero(maxLength: number, numero: string): string {
        return numero.padStart(maxLength, '0');
    }  

    private changeLabel(producto: string | undefined, it: ItemInterface<object>) {
        if (producto === undefined || producto === '') {
            it.visible = false;
            return;
        }

        it.label = 'Litros ' + this.lists.productos.values.find(
            product => product.value?.id === producto
        )?.value?.['nombre'] || 'no localizado';
        it.visible = true;
    }

    private checkWeatherVariables(field: ItemInterface<any>): any {
        const fecha = this.getFecha() ?? '';
        const finca = this.getFinca();
        const parcela = this.getParcela();
        const alertMessage = this.getAlertMessage(fecha, finca);
    
        if (alertMessage) {
            alert(alertMessage);
            return null;
        }
    
        field.disabled = true;
    
        return firstValueFrom(this.weatherService.getWeatherInfo(fecha, finca, parcela))
            .then((resp: { status: number; mensaje: string; data?: Climatologia }) => {
                if (resp.status === 400) {
                    alert(resp.mensaje);
                    field.disabled = false;
                    return null;
                }
    
                if (resp.data?.prevision) {
                    const hourlyData = resp.data.prevision.hourly[0];
                    return {
                        temperatura: hourlyData?.temperature,
                        precip: hourlyData?.precip,
                        hr: hourlyData?.humidity,
                        viento: hourlyData?.wind_speed,
                    };
                } else {
                    alert('No se han encontrado datos');
                    field.disabled = true;
                    return null;
                }
            })
            .catch((error: string) => {
                alert('No hay datos en esta fecha');
                console.error('Error:', error);
                field.disabled = true;
                return null;
            });
    }
    
    // Función auxiliar para obtener la fecha
    private getFecha(): string | null {
        let fecha = this.model.fecha_inicio || this.model.fecha_fin || null;
    
        if (fecha && typeof fecha === 'object') {
            fecha = moment(fecha).format('DD/MM/YYYY');
        }
    
        return fecha;
    }
    
    // Función auxiliar para obtener la finca
    private getFinca(): string {
        return this.model.id_finca
            ? (this.model.id_finca as unknown as FincasModel)?.id ?? ''
            : '';
    }
    
    // Función auxiliar para obtener la parcela
    private getParcela(): string {
        if (this.model.ids_parcelas) {
            return this.model.ids_parcelas.includes(';')
                ? this.model.ids_parcelas.split(';')[0] ?? ''
                : this.model.ids_parcelas;
        }
        return '0';
    }
    
    // Función auxiliar para generar el mensaje de alerta
    private getAlertMessage(fecha: string | null, finca: string): string {
        let message = '';
    
        if (!fecha) {
            message += 'No hay seleccionadas ni Fecha de inicio ni Fecha de fin.\n';
        }
    
        if (!finca) {
            message += 'No hay finca seleccionada.';
        }
    
        return message;
    }
    
    private getFormSections() {
        const sections: FormSectionInterface<any>[] = [
            {
                name: '',
                size: 12,
                visible: true,
                fields: this.getTiempoFields(),
                order: 1
            },
            {
                name: '',
                size: 12,
                visible: this.env.showTareasFitoPuntoKM,
                fields: this.getKMFields(),
                order: 2
            }, 
            {
                name: '',
                size: 12,
                visible: true,
                fields: this.getLugarFields(),
                order: 3
            },
            {
                name: '',
                size: 12,
                visible: true,
                fields: this.getTratamientoFields(),
                order: 4
            },
            {
                name: 'Condiciones meteorológicas',
                size: 6,
                visible: this.env.showClimatologia,
                fields: this.getClimaFields(),
                order: 5
            },
            {
                name: 'Mezclas sobrantes',
                size: 6,
                visible: this.env.appName === 'vginer',
                fields: this.getMezclasSobrantesFields(),
                order: 6
            },
            {
                name: 'Datos de la maquinaria',
                size: 6,
                visible: true,
                fields: this.getTractorFields(),
                order: 7
            },
            {
                name: 'Tarea finalizada',
                size: 6,
                visible: true,
                fields: this.getFinalFields(),
                order: 8
            },
            {
                name: 'Productos aplicados',
                size: 12,
                visible: this.env.appName === 'innovia' && this.ids_productos !== undefined,
                fields: this.getInnoviaFields(),
                order: 9
            },
            {
                name: '',
                size: 12,
                visible: this.env.appName === 'donjardin',
                fields: this.getOrdenTrabajoFields(),
                order: 10
            },
            {
                name: '',
                size: 12,
                visible: this.env.appName === 'donjardin',
                fields: this.getObsFields(),
                order: 11
            },
            {
                name: '',
                size: 12,
                visible: true,
                fields: this.getHiddenFields(),
                order: 12
            }
        ];
        return sections.sort((a, b) => (a.order ?? 0) - (b.order ?? 0));
    }

    private getHiddenFields() {
        const hiddenFields: ItemInterface<any>[] = [
            {
                field: 'id',
                size: 2,
                hasLabel: false,
                inputType: {type: InputType.HIDDEN},
                defaultValue: this.model.id,
                visible: true,
                order: 1
            },
            {
                field: 'id_cliente',
                size: 2,
                hasLabel: false,
                inputType: {type: InputType.HIDDEN},
                defaultValue: this.cliente?.id ? this.cliente?.id : this.lists.fincas.selected?.id_cliente,
                updateValue: () => this.cliente?.id ? this.cliente?.id : this.lists.fincas.selected?.id_cliente,
                visible: this.env.hasClients && this.env.userCanSeeClientes && this.formType !== FormType.DUPLICATION,
                order: 2
            },
            {
                field: 'id_usuario',
                size: 2,
                hasLabel: false,
                inputType: {type: InputType.HIDDEN},
                defaultValue: !this.env.showTareasMto ? 
                    this.usuario ? this.usuario.id :
                        (this.lists.fincas.selected || {})?.id_usuario : 
                    !FormType.EDITION ? this.usuario.id : '',
                updateValue: () => !this.env.showTareasMto ? 
                    this.usuario ? this.usuario.id :
                        (this.lists.fincas.selected || {})?.id_usuario : 
                    !FormType.EDITION ? this.usuario.id : '',
                visible: true,
                order: 3
            },
            {
                field: 'cultivo',
                size: 2,
                hasLabel: false,
                inputType: {type: InputType.HIDDEN},
                updateValue: () => 
                    (this.model.ids_parcelas as unknown as ParcelasModel[])?.map((parcela: ParcelasModel) => parcela.cultivo).join(';'),
                visible: true,
                order: 4
            },
            {
                field: 'variedad',
                size: 2,
                hasLabel: false,
                inputType: {type: InputType.HIDDEN},
                updateValue: () => (this.model.ids_parcelas as unknown as ParcelasModel[])?.map(
                    (parcela: ParcelasModel) => parcela.variedad).join(';'),
                visible: true,
                order: 5
            },
            {
                field: 'sup_cultivada',
                size: 2,
                hasLabel: false,
                inputType: {type: InputType.HIDDEN},
                updateValue: () => (this.model.ids_parcelas as unknown as ParcelasModel[])?.map(
                    (parcela: ParcelasModel) => parcela.superficie_cultivada).join(';'),
                visible: true,
                order: 6
            },
            {
                field: 'referencia_receta',
                size: 2,
                hasLabel: false,                
                inputType: {type: InputType.HIDDEN},
                rememberOnDuplicate: false,
                updateValue: () => this.referenciaReceta,
                visible: this.env.appName === 'pyf',
                order: 7
            },
            {
                field: 'signature_img',
                size: 2,
                hasLabel: false,               
                inputType: {type: InputType.HIDDEN},
                visible: this.env.showTareasMto,
                order: 8
            },
            {
                field: 'id_ref_usuario',
                size: 2,
                hasLabel: false,
                inputType: {type: InputType.HIDDEN},
                defaultValue: this.usuario.id,
                visible: this.env.appName === 'donjardin' && this.formType !== FormType.CREATION,
                retain: true,
                order: 9
            },
            {
                field: 'imagen',
                size: 2,
                hasLabel: false,
                inputType: {type: InputType.HIDDEN},
                visible: this.env.showTareasMto,
                order: 10
            },
            {
                field: 'image_after',
                size: 2,
                hasLabel: false,
                inputType: {type: InputType.HIDDEN},
                visible: this.env.showTareasMto,
                order: 11
            },
        ];

        if (this.formType === FormType.DUPLICATION) {
            hiddenFields.splice(1, 1);
        }

        return hiddenFields.sort((a, b) => (a.order ?? 0) - (b.order ?? 0));
    }
}